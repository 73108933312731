import React, { createContext, useState, useContext, useMemo, useRef } from "react"
import { useParams, Navigate } from "react-router-dom"
import ClientContext from "./ajax"
import { useEffect } from "react"
import isEqual from "lodash/isEqual"
import Beyond20Context from "./Beyond20Context"
import convertCharacter from "components/lotfp/beyond20"
import UserContext from "services/UserContext"
import RulesetContext from "./RulesetContext"

const CharacterContext = createContext(null)

export function CharacterContextProvider({ children }) {
    const { user } = useContext(UserContext)
    const { getCharacter, updateCharacterSection, addItem, updateItem, deleteItem } = useContext(ClientContext)
    const { ruleset } = useContext(RulesetContext)
    const { b20Settings } = useContext(Beyond20Context)
    const { character_id } = useParams()
    const [ character, setCharacter ] = useState(null)
    const [ pendingUpdates, setPendingUpdates ] = useState(false)
    const pendingUpdate = useRef(pendingUpdates)
    const [ willSave, setWillSave] = useState(false)
    const timerRef = useRef(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (pendingUpdates && willSave) {
            Promise.all(Object.entries(pendingUpdates).map(([section, data]) => {
                if (! isEqual(character[section], {...character[section], ...data})) {
                    return updateCharacterSection(character, section, data)
                } else {
                    return Promise.resolve(0)
                }
            })).then((updated) => {
                if (updated.reduce((m,i) => m+i, 0) > 0) {
                    refreshCharacter()
                }
                setWillSave(false)
                setPendingUpdates(false)
            })
        }
    }, [pendingUpdates, willSave])

    const updateCharacter = (character, section, data, force = false) => {
        if (force) {
            return updateCharacterSection(character, section, data).then(() => {
                refreshCharacter(character)
            })
        }
        if (!character || willSave) {
            return
        }
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        if (pendingUpdate.current) {
            pendingUpdate.current = {...pendingUpdate.current, [section]: {...pendingUpdate.current[section], ...data}}
        } else {
            pendingUpdate.current = {[section]: data}
        }
        if (pendingUpdates) {
            setPendingUpdates({...pendingUpdate.current,...pendingUpdates, [section]: {...pendingUpdates[section] ,...data}})
        } else {
            setPendingUpdates({...pendingUpdate.current, [section]: data})
        }
        timerRef.current = setTimeout(() => {
            setWillSave(true)
        }, 500)
    }


    const refreshCharacter = (_character) => {
        getCharacter(_character || character).then((result) => {
            if (result.ruleset_id !== ruleset.id) {
                setError(<Navigate to={"/characters"} />)
                return
            }
            setCharacter(result)
        })
    }


    useEffect(() => {
        if (b20Settings && character) {
            import(`../components/${character.ruleset_id}/beyond20`).then((convertCharacter) => {
                try {
                    const custom = new CustomEvent("Beyond20_settings", {
                        detail: [{
                            action: "settings", 
                            type: "character", 
                            character: convertCharacter(character, b20Settings), 
                            ts: Date.now()
                        }]
                    })
                    document.dispatchEvent(custom)    
        
                } catch(e) {
                    console.error(e)
                }
            }).catch((e) => {
                console.error(e)
            })
        }    
    }, [character, b20Settings])

    useEffect(() => {
        if (character_id && user) {
            refreshCharacter({id: character_id})
        }
    }, [character_id])



    const value = useMemo(() => {
    
        return {character, refreshCharacter, updateCharacter, convertCharacter, addItem, updateItem, deleteItem}
    }, [character, b20Settings])

    return (
        <CharacterContext.Provider
            value={value}
        >
            {!error ? children : error}
        </CharacterContext.Provider>
    )
}

export default CharacterContext
