import React, {useContext, useEffect, useState} from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import { Row, Col, Modal, Nav } from "react-bootstrap"
import NoticeContext from "services/NoticeContext"
import ClientContext from "services/ajax"
import whoweare from "../assets/register.jpg"
import whoweareSm from "../assets/register-sm.jpg"
import TermsOfServicePage from "./TermsOfService"
import { Navigate, NavLink, Link } from "react-router-dom"
import UserContext from "services/UserContext"
import BlurredLoaderBackgroundImage from "components/BlurredLoaderBackgroundImage"

function SignUpForm({name, email, setName, setEmail, onError}) {
    const { registerUser } = useContext(ClientContext)
    const [sent, setSent] = useState()
    const [agreed, setAgreed] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showTos, setShowTos] = useState(false)

    return <Container>
        <div className="vstack gap-3 lonely">
            <h1 className={"brand"}>Sign Up:</h1>
            {sent && <h1>a Registration Email has been sent to your address with further instructions. If you do not receive it within 5 minutes go to <Link to="/forgot-password">Forgot Password</Link></h1>}
            {!sent && <>
                <Form.Group>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control type='text' onChange={({target}) => setName(target.value)}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control type="email" onChange={({target}) => setEmail(target.value)}></Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Check className={"d-inline-block"} onChange={({target}) => {setAgreed(target.checked)}}></Form.Check> <Form.Text className={"d-inline-block"}>I agree to the <Link to="#tos" onClick={() => setShowTos(true)}>terms and conditions</Link> of OSRBeyond.com</Form.Text>
                </Form.Group>
                <div className="hstack gap-2">
                    <Button disabled={(!(name && email)) || loading} onClick={() => {
                        if (!agreed) {
                            return onError("You must agree to the terms of service to register.")
                        }
                        setLoading(true)
                        registerUser(email, name).then(({status, message}) => {
                            if (status === "error") {return onError(message)}
                            setSent(true)
                            setLoading(false)
                        })
                    }}>Register</Button> 
                </div>
                <Modal size="xl" show={showTos} onHide={() => setShowTos(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms of Service</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TermsOfServicePage/>
                    </Modal.Body>
                </Modal>
            </>}
        </div>
    </Container>
}

function SignInForm({email, password, setEmail, forwardTo, setPassword, onError}) {
    const { loginUser } = useContext(ClientContext)
    const [loading, setLoading] = useState(false)
    const [sendForward, setSendForward] = useState(false)
    const {setUser} = useContext(UserContext)

    if (sendForward) {
        return <Navigate to={forwardTo}/>
    }

    return <Container>
        <div className="vstack gap-3 lonely">
            <h1 className={"brand"}>Sign In:</h1>
            <Form.Group>
                <Form.Label>Email:</Form.Label>
                <Form.Control type="email" onChange={({target}) => setEmail(target.value)}></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Password:</Form.Label>
                <Form.Control type="password" onChange={({target}) => setPassword(target.value)}></Form.Control>
            </Form.Group>
            <div className="hstack gap-2">
                <Button disabled={(!(email && password)) || loading} onClick={() => {
                    setLoading(true)
                    loginUser(email, password).then(({status, message, user}) => {
                        if (status === "error") { 
                            setLoading(false)
                            return onError(message) 
                        }
                        setUser(user)
                        sessionStorage.setItem("user", JSON.stringify(user))
                        setSendForward(true)
                        setLoading(false)
                    })
                }}>Login</Button> 
                <Button variant="danger" href={"/forgot-password"}>Forgot Password</Button>
            </div>
        </div>
    </Container>

}


export default function Register({path}) {
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [forwardTo, setForwardTo] = useState("/welcome")
    const {addNotice} = useContext(NoticeContext)

    const onError = (message) => {
        addNotice("Error", message, "danger")
    }

    useEffect(() => {
        if (path !== "/register" && path !== "/login") {
            setForwardTo(path)
        }
    }, [])


    return <Container fluid>
        <Container fluid className={"p-0"}>
            <Row>
                <BlurredLoaderBackgroundImage Component={Col} sm={whoweareSm} src={whoweare}/>
                {/* <Col style={{
                    backgroundImage: `url(${whoweare})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "100vh"
                }}>
                
                </Col> */}
                <Col className="d-flex flex-column">
                    <Row className={"h-25"}>
                    </Row>
                    <Row>
                        <Tab.Container activeKey={path}>
                            <Nav variant="tabs">
                                <Nav.Item>
                                    <Nav.Link as={NavLink} to="/register" replace={true}>Sign Up</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link as={NavLink} to="/login" replace={true}>Sign In</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="/register">
                                    <SignUpForm email={email} name={name} setEmail={setEmail} forwardTo={forwardTo} setName={setName} onError={onError}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="/login">
                                    <SignInForm email={email} password={password} setEmail={setEmail} forwardTo={forwardTo} setPassword={setPassword} onError={onError}/>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Row>
                </Col>
            </Row>  

        </Container>
    </Container>
}