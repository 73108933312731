import React, {useState, createContext, useEffect, useMemo, useContext} from "react"
import ClientContext from "./ajax"
import { useCallback } from "react"
import {CLIENT_HOST} from "./api"
import UserContext from "./UserContext"

const RulesetContext = createContext(null)

const rulesets = [
    {id: "lotfp", name: "Lamentations of the Flame Princess", license: "This product is an independent production by Quest Giver Games LTD and is not affiliated with Lamentations of the Flame Princess. Lamentations of the Flame Princess is a registered trademark owned by James Edward Raggi IV.", active: true, img: "/assets/lotfp_black.jpg", url: "https://lotfp.com/"},
    {id: "troika", name: "Troika!", active: true, license: "OSRBeyond is an independent production by Quest Giver Games LTD and is not affiliated with the Melsonian Arts Council.", url: "https://troika-srd.netlify.app/#/", img: "/assets/troika.png"},
    {id: "ose", name: "Old School Essentials", active: true, license: "Old-School Essentials is a trademark of Necrotic Gnome. The trademark and Old-School Essentials logo are used with permission of Necrotic Gnome, under license", url: "https://oldschoolessentials.necroticgnome.com/srd/index.php/Main_Page", img: "/assets/ose.png"},
    {id: "bfrp", name: "Basic Fantasy Role Playing", active: true, license: "Basic Fantasy Role-Playing Game 4th Edition is distributed under the terms of the CC BY-SA 4.0 International license.", url: "https://basicfantasy.org/srd"},
]

rulesets.forEach((ruleset) => {
    console.log(CLIENT_HOST)
    const findMeat = /(.*)osrbeyond(\..*)/ig
    console.log(findMeat.test(CLIENT_HOST))
    ruleset.hostname = CLIENT_HOST.replace(findMeat, `$1${ruleset.id}.osrbeyond$2`)
})
export function RulesetContextProvider({children}) {
    const { user } = useContext(UserContext)
    const {hostname} = window.location
    const hostParts = hostname.split(".")
    if (hostParts.length > 3) { // remove it
        hostParts.shift()
    }
    const initialRuleset = hostParts[0] === "localhost" ? rulesets[0] : rulesets.find((ruleset) => ruleset.id === hostParts[0])
    let storedRuleset = {}
    try {
        storedRuleset = JSON.parse(localStorage.getItem("ruleset"))
    } catch (e) {
        storedRuleset = rulesets[0]
    }

    if (initialRuleset && initialRuleset?.id !== storedRuleset?.id) {
        try {
            localStorage.setItem("ruleset", JSON.stringify(initialRuleset))
        } catch (e) {
            localStorage.setItem("ruleset", JSON.stringify(rulesets[0]))
        }
    }
    const [ruleset, setRuleset] = useState(initialRuleset || storedRuleset)
    const [sources, setSources] = useState(null)
    const [sourcesLoaded, setSourcesLoaded] = useState(false)
    const {getResources, getSource} = useContext(ClientContext)
    if (!ruleset) {
        setRuleset(rulesets[0])
    }

    const loadSources = useCallback(() => {
        getResources(ruleset).then((resources) => {
            setSourcesLoaded(Promise.all(resources.map((resource) => {
                return getSource(ruleset, resource)
            })).then((sources) => {
                const _sources = resources.reduce((m, key, i) => {
                    m[key] = sources[i]
                    return m
                }, {})
                setSources(_sources)
            }).catch(() => {
                setSourcesLoaded(false)
                return loadSources()
            }))
        })
    }, [ruleset])

    useEffect(() => {
        if (ruleset && user && !sourcesLoaded) {
            loadSources()
        }
    }, [ruleset, user])

    const value = useMemo(() => {
        return {
            ruleset, 
            setRuleset, 
            rulesets,
            sources,
            sourcesLoaded    
        }
    }, [sources, ruleset])

    return <RulesetContext.Provider value={value}>
        {children}
    </RulesetContext.Provider>
}

export default  RulesetContext