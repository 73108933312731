import React from "react"
import ReactDOM from "react-dom"
import App from "App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "reportWebVitals"

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
)

function sendToAnalytics({ id, name, value }) {
    ga("send", "event", {
        eventCategory: "Web Vitals",
        eventAction: name,
        eventValue: Math.round(name === "CLS" ? value * 1000 : value),
        eventLabel: id,
        nonInteraction: true,
    })
}
  
reportWebVitals((typeof ga !== "undefined") ? sendToAnalytics : console.log)

serviceWorkerRegistration.register()