import React, { useContext, useEffect, useState } from "react"
import { ListGroup, Row, Col, Button, Form, Container, InputGroup } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"
import RulesetContext from "services/RulesetContext"

Array.range = function(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export function getModifier(character, skill) {
    let finalMod = 0
    if (skill.name === "Languages") {
        finalMod += parseInt(character?.attributes?.intelligence?.modifier.replace("+", "") || 0, 10)
    } else if (skill.name === "Open Doors") {
        finalMod += parseInt(character?.attributes?.strength?.modifier.replace("+", "") || 0, 10)
    }
    return finalMod
}

export default function CharacterSkills() {
    const {character, updateCharacter} = useContext(CharacterContext)
    const {sources} = useContext(RulesetContext)
    const [skills, setSkills] = useState(character?.skills?.skills?.slice() || [])
    const [skillPoints, setSkillPoints] = useState({...character?.skills?.skillPoints} || {levels: []})

    useEffect(() => {
        resetSkillPoints()
    }, [])

    const getSpentPoints = (level) => {
        return Object.values(level?.spent || {none: 0}).reduce((m, v) => m + (v || 0), 0)
    }

    useEffect(() => {
        const progressionLevel = character?.class?.progression?.find(({level}) => {
            return level === (character?.instance?.level || 1)
        })

        setSkills((sources?.skills || []).map((skill) => {
            const s = progressionLevel?.skills?.find(({name}) => name === skill.name)
            skill.rank = (s?.rank || skill?.defaultValue)
            return skill
        }))
    }, [character, sources])

    const resetSkillPoints = () => {
        if (character?.id && sources) {
            if (!skillPoints.levels) {
                skillPoints.levels = []
            }
            const {levels} = skillPoints
            for (let i = 0; i < character?.instance?.level; i++) {
                const levelProgress = character?.class?.progression?.find(({level}) => level === i + 1)
                if (levels) {
                    levels[i] = levels[i] || {}
                    levels[i].max = levelProgress.skillPoints
                    levels[i].current = levelProgress.skillPoints - getSpentPoints(levels[i])
                    levels[i].spent = levels[i]?.spent || {}
                }
            }
            setSkillPoints({levels})
        }
    }

    useEffect(() => {
        if (character?.id) { 
            updateCharacter(character, "skills", {skills, skillPoints})
        }
    }, [skills, skillPoints])

    let current = 0
    let max = 0

    if (!character) return null
    if (character?.class?.name === "Specialist") {
        if (!skillPoints.levels) {
            resetSkillPoints()
        } else {
            const { c, m} = (skillPoints?.levels || []).reduce((m, level) => {
                m.c += level.current || 0
                m.m += parseInt(level.max, 10) || 0
                return m
            }, {c: 0, m: 0})
            current = c
            max = m
        }
    
    }

    console.log(character.instance.level)

    return <Container>
        <h1 className={"brand"}>Skills</h1>
        {character?.class?.name === "Specialist" && <Row>
            <Col>
                <h3 className={"brand"}>Skill Points: {current} / {max} </h3>        
            </Col>
            <Col>
                <Button onClick={resetSkillPoints}>Reset</Button>
            </Col>
        </Row>}
        <ListGroup>
            <ListGroup.Item>
                <Row>
                    <Col xs={8}>Base | Mod | {Array.range(1, character?.instance?.level || 1).map((level) => {
                        return `${level} |`
                    })}
                    </Col>
                    <Col xs={4}>Advanced Skill</Col>
                </Row>
            </ListGroup.Item>
            {skills.map((skill, i) => {
                const inSaved = character?.skills?.skills.find(({name}) => name === skill.name)
                const {name, rank} = skill
                return <ListGroup.Item key={`skills-${i}`} variant={inSaved ? "success" : "warning"} >
                    <Row>
                        <Col xs={8}>
                            <InputGroup>
                                <InputGroup.Text>{rank}</InputGroup.Text>
                                <InputGroup.Text>{getModifier(character, skill)}</InputGroup.Text>
                                {Array.range(1, character?.instance?.level || 1).map((level) => {
                                    return <InputGroup.Text key={`${skill}-${level}`}>{(skillPoints.levels || [])[level - 1]?.spent?.[name] || 0}</InputGroup.Text>
                                })}
                                <Button disabled={current < 1 || skill.name === "Open Doors"} className={"form-control-sm"} onClick={() => {
                                    const lowestLevelWithPoints = skillPoints.levels.findIndex(({current}) => current > 0)
                                    if (lowestLevelWithPoints > -1) {
                                        const level = {...skillPoints.levels[lowestLevelWithPoints]}
                                        level.current -= 1
                                        level.spent[name] = level.spent[name] || 0
                                        level.spent[name] += 1
                                        skillPoints.levels[lowestLevelWithPoints] = level
                                        setSkillPoints({levels: skillPoints.levels.slice()})
                                    }
                                }}><i className={"bi-plus"}/></Button>
                            </InputGroup>
                        </Col>
                        <Col xs={4}><Form.Text className={"form-control-sm"}>{name}</Form.Text></Col>
                    </Row>
                </ListGroup.Item>
            })}
        </ListGroup>
    </Container>
}