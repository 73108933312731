import React, { createContext, useContext, useState, useMemo } from "react"
import API_HOST from "./api"
import NoticeContext from "./NoticeContext"

export const IsSavingContext = createContext(false)

export function IsSavingProvider(props) {
    const [isSaving, setIsSaving] = useState(false)
    const value = useMemo(() => ({isSaving, setIsSaving}), [isSaving])
    return <IsSavingContext.Provider value={value} {...props}/>
}

const ClientContext = createContext(null)

export function ClientProvider({children}) {
    const {addNotice} = useContext(NoticeContext)
    const {setIsSaving} = useContext(IsSavingContext)
    const getCurrentUser = () => {
        try {
            return JSON.parse(sessionStorage.getItem("user"))
        } catch(e) {
            sessionStorage.removeItem("user")
            return null
        }
    }
    
    const getAuthHeaders = () => {
        const user = getCurrentUser()
    
        if (user && user.token) {
            return { Authorization: `Bearer ${user.token}`, "x-access-token": user.token}
        }
        return {}
    }
    
    const ajaxDelete = (path) => {
        return fetch(`${API_HOST}/api/${path}`, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                ...getAuthHeaders(),
                "Content-Type": "application/json"
            },
            redirect: "manual"
        })
    }
    
    const ajaxPost = (path, payload) => {
        setIsSaving(true)
        return fetch(`${API_HOST}/api/${path}`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                ...getAuthHeaders(),
                "Content-Type": "application/json"
            },
            redirect: "manual",
            body: JSON.stringify(payload)
        }).then((r) => {
            setIsSaving(false)
            return r
        })
    }
    
    const ajaxPut = (path, payload) => {
        setIsSaving(true)
        return fetch(`${API_HOST}/api/${path}`, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                ...getAuthHeaders(),
                "Content-Type": "application/json"
            },
            redirect: "manual",
            body: JSON.stringify(payload)
        }).then((r) => {
            setIsSaving(false)
            return r
        })
    }
    
    const ajaxUpload = (path, payload, {related_to}) => {
        const formData = new FormData()
        formData.append("file", payload)
        formData.append("related_to", related_to)
        return fetch(`${API_HOST}/api/${path}${related_to}`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                ...getAuthHeaders()
            },
            body: formData
        })
    }
    
    const ajaxGet = (path) => {
        return fetch(`${API_HOST}/api/${path}`, {
            method: "GET",
            mode: "cors",
            credentials: "include",
            headers: {
                ...getAuthHeaders(),
                "Content-Type": "application/json"
            },
            redirect: "manual"
        }).then()
    }
    
    const checkToken = () => {
        return ajaxGet("user/check").then((res) => {
            if (res.status !== 200) {
                return null
            }
            return res.json()
        })
    }
    
    const handleAuthFailureReturnJson = async (r) => {
        const {status} = r
        if (status === 401 || status === 403) {
            const output = await r.json()
            addNotice({type: "danger", message: output.message})
            logoutUser().then(() => {
                window.location.assign("/login")
            })
        }
        if (status === 404) {
            throw new Error("Resource not found!")
        }
        if (status === 500) {
            throw new Error("Server error!")
        }
        return r.json()
    }

    const admin = {
        getMailchimpSubscribers() {
            return ajaxGet("admin/subscribers").then(handleAuthFailureReturnJson)
        },
        getUsers() {
            return ajaxGet("admin/user").then(handleAuthFailureReturnJson)
        },
        deleteUser(id) {
            return ajaxDelete(`admin/user/${id}`).then(handleAuthFailureReturnJson)
        },
        getTables() {
            return ajaxGet("admin/database/tables").then(handleAuthFailureReturnJson)
        },
        runSeed() {
            return ajaxPost("admin/database/seed").then(handleAuthFailureReturnJson)
        },
        getMigrations() {
            return ajaxGet("admin/database/migrations").then(handleAuthFailureReturnJson)
        },
        migrateUp() {
            return ajaxPost("admin/database/migrate_up").then(handleAuthFailureReturnJson)
        },
        migrateDown() {
            return ajaxPost("admin/database/migrate_down").then(handleAuthFailureReturnJson)
        },
        getBuckets() {
            return ajaxGet("admin/image/buckets").then(handleAuthFailureReturnJson)
        },
        createBucket(bucket) {
            return ajaxPost(`admin/image/buckets${bucket? `/${bucket}` : ""}`).then(handleAuthFailureReturnJson)
        },
        getImages() {
            return ajaxGet("admin/image").then(handleAuthFailureReturnJson)
        },
        migrateImageToS3(image) {
            return ajaxPost("admin/image/migrate", image).then(handleAuthFailureReturnJson)
        },
        getWhitelist(id = null) {
            return ajaxGet(`admin/user/whitelist${id ? `/${id}` : ""}`).then(handleAuthFailureReturnJson)
        },
        inviteToWhitelist(cohort, invites) {
            return ajaxPost("admin/user/whitelist", {invites, cohort}).then(handleAuthFailureReturnJson)
        },
        updateWhitelistInvitee(id, invitee) {
            return ajaxPut(`admin/user/whitelist/${id}`, invitee).then(handleAuthFailureReturnJson)
        }
    }
  
    const emailSignup = (email) => {
        return ajaxPost("email/signup", {email}).then(handleAuthFailureReturnJson)
    }

    const registerUser = (email, name) => {
        return ajaxPost("user/register", {email, name}).then(handleAuthFailureReturnJson)
    }
  
    const loginUser = (email, password) => {
        return ajaxPost("user/login", {email, password}).then(handleAuthFailureReturnJson)
    }
  
    const logoutUser = () => {
        return ajaxPost("user/logout").then(handleAuthFailureReturnJson)
    }

    const updateUser = (user) => {
        return ajaxPut(`user/${getCurrentUser().id}`, user).then(handleAuthFailureReturnJson)
    }
  
    const forgotPassword = (email) => {
        return ajaxPost("user/forgot-password", {email}).then(handleAuthFailureReturnJson)
    }
  
    const resetPassword = (email, password1, password2, token) => {
        return ajaxPost("user/reset-password", {email, password1, password2, token}).then(handleAuthFailureReturnJson)
    }
  
    const uploadImage = (file, related_to) => {
        return ajaxUpload("image/", file, {related_to}).then(handleAuthFailureReturnJson)
    }
  
    const deleteImage = (id) => {
        return ajaxDelete(`image/${id}`).then(handleAuthFailureReturnJson)
    }
  
    const getRelatedImages = (id) => {
        return ajaxGet(`image/${id}`).then(handleAuthFailureReturnJson)
    }
  
    const getCharacters = (ruleset) => {
        if (ruleset) {
            return ajaxGet(`ruleset/${ruleset.id}/character`).then(handleAuthFailureReturnJson)
        } else {
            return ajaxGet("character").then(handleAuthFailureReturnJson)
        }
    } 
  
    const getRandomCharacter = (ruleset) => {
        return ajaxGet(`ruleset/${ruleset.id}/character/random`).then(handleAuthFailureReturnJson)
    }

    const getRandomName = (race) => {
        return ajaxGet(`character/name/${race}`).then(handleAuthFailureReturnJson)
    }

    const getCampaigns = (ruleset) => {
        if (ruleset) {
            return ajaxGet(`ruleset/${ruleset.id}/campaign`).then(handleAuthFailureReturnJson)
        } else {
            return ajaxGet("campaign").then(handleAuthFailureReturnJson)
        }
    }

    const getCampaign = (campaign) => {
        return ajaxGet(`campaign/${campaign.slug}`).then(handleAuthFailureReturnJson)
    }

    const createCampaign = (ruleset, campaign) => {
        return ajaxPost(`ruleset/${ruleset.id}/campaign`, campaign).then(handleAuthFailureReturnJson)
    }

    const updateCampaign = (campaign, {cover, name, description}) => {
        return ajaxPut(`campaign/${campaign.slug}`, {cover, name, description}).then(handleAuthFailureReturnJson)
    }

    const deleteCampaign = (campaign) => {
        return ajaxDelete(`campaign/${campaign.id}`).then(handleAuthFailureReturnJson)
    }

    const joinCampaign = (campaign, character) => {
        return ajaxPost(`campaign/${campaign.id}/join`, character).then(handleAuthFailureReturnJson)
    }

    const removeFromCampaign = (campaign, character) => {
        return ajaxDelete(`campaign/${campaign.id}/remove/${character.id}`).then(handleAuthFailureReturnJson)
    }

    const createCharacter = (ruleset, character) => {
        return ajaxPost(`ruleset/${ruleset}/character`, character).then(handleAuthFailureReturnJson)
    }
  
    const updateCharacterSection = (character, section, data) => {
        return ajaxPut(`character/${character.id}/data/${section}`, data).then(handleAuthFailureReturnJson)
    }
  
    const getCharacter = (character) => {
        return ajaxGet(`character/${character.id}`).then(handleAuthFailureReturnJson)
    }
  
    const getInventory = (character) => {
        return ajaxGet(`character/${character.id}/inventory`).then(handleAuthFailureReturnJson)
    }

    const getItem = (character, id) => {
        return ajaxGet(`character/${character.id}/inventory/${id}`).then(handleAuthFailureReturnJson)
    }

    const updateItem = (character, id, item) => {
        return ajaxPut(`character/${character.id}/inventory/${id}`, item).then(handleAuthFailureReturnJson)
    }

    const addItem = (character, item) => {
        return ajaxPost(`character/${character.id}/inventory`, item).then(handleAuthFailureReturnJson)
    }

    const deleteItem = (character, id) => {
        return ajaxDelete(`character/${character.id}/inventory/${id}`).then(handleAuthFailureReturnJson)
    }

    const deleteCharacter = (character) => {
        return ajaxDelete(`character/${character.id}`).then(handleAuthFailureReturnJson)
    }
  
    const getSources = (related_to) => {
        return ajaxGet(`sources/${related_to}`).then(handleAuthFailureReturnJson)
    }
  
    const getTroikaBackgrounds = (character) => {
        if (!character.id) {
            return Promise.reject(new Error("Invalid parameter, Character is required."))
        }
        return ajaxGet(`sources/troika/${character.id}/backgrounds`).then(handleAuthFailureReturnJson)
    }

    const getTroikaSpells = (character) => {
        if (!character.id) {
            return Promise.reject(new Error("Invalid parameter, Character is required."))
        }
        return ajaxGet(`sources/troika/${character.id}/spells`).then(handleAuthFailureReturnJson)
    }
  
    const getResources = (ruleset) => {
        return ajaxGet(`ruleset/${ruleset.id}/resources`).then(handleAuthFailureReturnJson)
    }

    const getSource = (ruleset, source) => {
        return ajaxGet(`sources/${ruleset.id}/${source}`).then(handleAuthFailureReturnJson)
    }

    const getLotfpSkills = (character) => {
        if (!character.id) {
            return Promise.reject(new Error("Invalid parameter, Character is required."))
        }
        return ajaxGet(`sources/lotfp/${character.id}/skills`).then(handleAuthFailureReturnJson)
    }

    const getLotfpSpells = (character) => {
        if (!character.id) {
            return Promise.reject(new Error("Invalid parameter, Character is required."))
        }
        return ajaxGet(`sources/lotfp/${character.id}/spells`).then(handleAuthFailureReturnJson)
    }

    const getLotfpClasses = (character) => {
        if (!character.id) {
            return Promise.reject(new Error("Invalid parameter, Character is required."))
        }
        return ajaxGet(`sources/lotfp/${character.id}/classes`).then(handleAuthFailureReturnJson)
    }

    const getLotfpItems = (character) => {
        if (!character.id) {
            return Promise.reject(new Error("Invalid parameter, Character is required."))
        }
        return ajaxGet(`sources/lotfp/${character.id}/items`).then(handleAuthFailureReturnJson)
    }

    const getSubscriptionProducts = () => {
        return ajaxGet("subscribe/products").then(handleAuthFailureReturnJson)

    }

    const getUserSubscription = () => {
        return ajaxGet("subscribe").then(handleAuthFailureReturnJson)
    }

    const getUserPaymentMethods = () => {
        return ajaxGet("subscribe/payment-methods").then(handleAuthFailureReturnJson)
    }

    const getStripeMode = () => {
        return ajaxGet("subscribe/mode").then(handleAuthFailureReturnJson)
    }

    const createSetupIntent = () => {
        return ajaxPost("subscribe/setup-intent").then(handleAuthFailureReturnJson)
    }

    const createSubscription = (subscriptionItem) => {
        return ajaxPost("subscribe", subscriptionItem).then(handleAuthFailureReturnJson)
    }

    const sendFeedback = (email, message) => {
        return ajaxPost("email/feedback", {email, message}).then(handleAuthFailureReturnJson)
    }

    const value = useMemo(() => {
        return {
            deleteCharacter, getSources, getTroikaBackgrounds, getTroikaSpells, getLotfpSkills, getLotfpClasses, getLotfpItems, getLotfpSpells, getRandomCharacter,
            getCharacter, getCharacters, getRelatedImages, getCampaigns, getCampaign, createCampaign, updateCampaign, deleteCampaign, joinCampaign, getRandomName,
            updateCharacterSection, createCharacter, deleteImage, emailSignup, removeFromCampaign, getInventory, getItem, updateItem, addItem, deleteItem,
            uploadImage, registerUser, updateUser, loginUser, logoutUser, forgotPassword, getResources, getSource, createSubscription, getUserPaymentMethods,
            resetPassword, admin, checkToken, getCurrentUser, getSubscriptionProducts, getUserSubscription, getStripeMode, createSetupIntent, sendFeedback
        }
    }, [])

    return <ClientContext.Provider value={value}>
        {children}
    </ClientContext.Provider>
}

export default ClientContext