import React, { useContext, useState, useEffect, lazy, Suspense } from "react"
import { Button, Container, Row, Col, InputGroup, Form, DropdownButton, Dropdown, Card } from "react-bootstrap"
import RulesetContext from "services/RulesetContext"
import UserContext from "services/UserContext"
import NoticeContext from "services/NoticeContext"
import ClientContext from "services/ajax"
import {NavLink, Link} from "react-router-dom"
import joe from "../assets/joe1.png"

export function EmptyList() {
    const {ruleset} = useContext(RulesetContext)
    return <Card className="mt-5 w-100">
        <Card.Body>
            <Row>
                <Col style={{
                    backgroundImage: `url(${joe})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "500px"
                }}>
                </Col>
                <Col>
                    <h3>You haven&apos;t made any characters yet for {ruleset.name} </h3>
                    <p>To get started you can click the Create Character button above. From there you will have the option to create a standard character or to create a random one. Random character are generated using <Link to={"https://character.totalpartykill.ca"}>Total Party Kill</Link>.</p>
                </Col>
            </Row>
        </Card.Body>
    </Card>
}

export default function Characters() {
    const {ruleset} = useContext(RulesetContext)
    const {user} = useContext(UserContext)
    const {addNotice} = useContext(NoticeContext)
    const {getCharacters, deleteCharacter} = useContext(ClientContext)
    const [slots, setSlots] = useState(0)
    const [availableSlots] = useState((user.roles.includes("subscriber") || user.roles.includes("administrator")) ? "Unlimited" : 5)
    const [characters, setCharacters] = useState([])
    const [filterTerm, setFilterTerm] = useState("")
    const [sort, setSort] = useState()

    const sortOldestNewest = (key) => (reverse) => (a, b) => {
        if (reverse) {
            return new Date(b[key]) - new Date(a[key])
        } else {
            return new Date(a[key]) - new Date(b[key])
        }
    }

    const sortText = (key) => (reverse) => (a, b) => {
        if (reverse) {
            return b.settings[key].localeCompare(a.settings[key])
        } else {
            return a.settings[key].localeCompare(b.settings[key])
        }
    }

    function refreshCharacters() {
        getCharacters(ruleset).then(characters => {
            setCharacters(characters)
            setSlots(characters.length)
        })
    }

    const CharacterCard = lazy(() => import(`components/${ruleset.id}/CharacterCard`))

    useEffect(() => {
        if (ruleset) {
            refreshCharacters()
        } else {
            addNotice("Error", "Select a ruleset above to view your characters.", "danger")    
        }
    }, [ruleset])

    const sorts = {
        cn: sortOldestNewest("created_at")(true),
        co: sortOldestNewest("created_at")(false),
        na: sortText("name")(false),
        nz: sortText("name")(true),
        mn: sortOldestNewest("updated_at")(true),
        mo: sortOldestNewest("updated_at")(false)
    }

    return <Container className="mt-4">
        <Container className={"justify-content-between"}>
            <Row>
                <Col>
                    <h1 className="brand">My Characters</h1>
                </Col>
                <Col className={"d-flex flex-column align-items-end"}>
                    { ruleset?.id && <NavLink className="btn btn-primary" to={`/characters/builder/${ruleset.id}`} replace={true}>Create a Character</NavLink>}
                </Col>
            </Row>
            <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
            <Row>
                <h3 className="brand">Slots: {slots}/{availableSlots}</h3>
            </Row>
            <Row>
                <Col className={"d-flex flex-row"}>
                    <InputGroup className={"w-75"}>
                        <InputGroup.Text>
                            <i className="bi bi-search"></i>
                        </InputGroup.Text>
                        <Form.Control type={"search"} value={filterTerm} onChange={({target}) => {
                            setFilterTerm(target.value)
                        }}/>
                    </InputGroup>
                    <DropdownButton title="Sort By" key={sort} onSelect={(eventKey) => {
                        setSort(eventKey)
                    }}  className={"w-25 d-flex flex-column align-items-end"}>
                        <Dropdown.Item eventKey={"cn"}>Created: Newest</Dropdown.Item>
                        <Dropdown.Item eventKey={"co"}>Created: Oldest</Dropdown.Item>
                        <Dropdown.Item eventKey={"na"}>Name: A to Z</Dropdown.Item>
                        <Dropdown.Item eventKey={"nz"}>Name: Z to A</Dropdown.Item>
                        <Dropdown.Item eventKey={"mn"}>Modified: Latest</Dropdown.Item>
                        <Dropdown.Item eventKey={"mo"}>Modified: Oldest</Dropdown.Item>
                    </DropdownButton>
                </Col>
            </Row>
        </Container>
        <Container fluid className={"d-flex flex-row flex-wrap justify-content-between"}>
            <Suspense fallback={<div>Loading...</div>}>
                {characters.length > 0 && characters.filter((character) => {
                    if (!filterTerm) {
                        return character
                    }
                    return character?.settings?.name?.toLowerCase().includes(filterTerm?.toLowerCase()) || character?.class?.name?.toLowerCase()?.includes(filterTerm?.toLowerCase()) || character?.traits?.gender?.toLowerCase()?.includes(filterTerm?.toLowerCase())
                }).sort(sorts[sort]).map((character) => {
                    return <CharacterCard key={`character-${character.id}`} character={character}>
                        <Button className='ms-2' href={`/character/${character.id}`}>View</Button>
                        <Button className='ms-2' href={`/characters/builder/${character.ruleset_id}/standard/${character.id}/0`}><i className='bi-pencil'/></Button>
                        <Button className='ms-2' variant="danger" onClick={() => {
                            deleteCharacter(character).then(refreshCharacters)
                        }}><i className='bi-trash'/></Button>
                    </CharacterCard>
                })}
                {!characters.length && <EmptyList/>}
            </Suspense>
        </Container>
    </Container>
}