import React from "react"
import AvatarSelector from "components/AvatarSelector"
import SwitchList from "components/SwitchList"
import TextControl from "components/TextControl"
import { Container, ListGroupItem, InputGroup, Form } from "react-bootstrap"

const renderer = (selectedItems, setSelectedItems) => {
    const itemRenderer =  (item, i) => {
        return <ListGroupItem key={i}>
            <InputGroup>
                <InputGroup.Text>
                    <Form.Check checked={(selectedItems || []).map(JSON.stringify).includes(JSON.stringify(item))} onChange={() => {
                        if ((selectedItems || []).includes(item)) {
                            selectedItems.splice(selectedItems.indexOf(item), 1)
                            setSelectedItems([...selectedItems])
                        } else {
                            setSelectedItems([...selectedItems, item])
                        }
                    }} type={"checkbox"}></Form.Check>
                </InputGroup.Text>
                <InputGroup.Text  className={"overflow-hidden w-75 d-inline-block text-start"} style={{textOverflow: "ellipsis"}}>{item.title} by {item.author || item.publisher}</InputGroup.Text>
            </InputGroup>
        </ListGroupItem>
    }
    return itemRenderer
}

export default function CharacterSettings() {
    return <Container>
        <h1 className={"brand"}>Settings</h1>
        <h2 className={"brand"}>Avatar</h2>
        <p>Upload any number of images associated with this character and select one to be your avatar.</p>
        <AvatarSelector keys="avatar" sectionKey="settings" data='ajax.getRelatedImages'/>
        <h2 className={"brand"}>Character Name</h2>
        <p>Select a name for your character or use the Random Fantasy Name Generator.</p>
        <TextControl label="Name" sectionKey="settings" keys="name"/>
        <h2 className={"brand"}>Sources</h2>
        <p>Select the sources from which your character may choose. These may be limited by your Referee or Campaign.</p>
        <SwitchList data="ajax.getSources" sectionKey="settings" keys={"sources"} renderer={renderer}/>
    </Container>
}