import React, {createContext, useState, useEffect, useMemo} from "react"

const Beyond20Context = createContext(null)


export function Beyond20ContextProvider(props) {
    const [b20, setB20] = useState(null)
    const [b20Settings, setB20Settings] = useState(null)

    function getSettings({detail: [settings]}) {
        if (settings) {
            setB20(settings)
            setB20Settings(settings)
        }
    }
    
    useEffect(() => {
        document.addEventListener("Beyond20_Loaded", setB20)
        return () => {
            document.removeEventListener("Beyond20_Loaded", setB20)
        }        
    }, [])

    useEffect(() => {
        document.addEventListener("Beyond20_UpdateSettings", getSettings)
        return () => {
            document.removeEventListener("Beyond20_UpdateSettings", getSettings)
        }
    }, [b20])

    const value = useMemo(() => ({
        b20,
        b20Settings
    }), [b20, b20Settings])

    return <Beyond20Context.Provider value={value} {...props}></Beyond20Context.Provider>
}

export default Beyond20Context