import { Container, Row, Col, Card } from "react-bootstrap"
import RulesetContext from "services/RulesetContext"
import NoticeContext from "services/NoticeContext"
import React, { useContext, useState, useEffect } from "react"
import ClientContext from "services/ajax"
import { NavLink } from "react-router-dom"
import CampaignCard from "components/CampaignCard"
import joe from "../assets/joe2.png"

export function EmptyList() {
    const {ruleset} = useContext(RulesetContext)
    return <Card className="mt-5 w-100">
        <Card.Body>
            <Row>
                <Col style={{
                    backgroundImage: `url(${joe})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "500px"
                }}>
                </Col>
                <Col>
                    <h3>You haven&apos;t made any campaigns yet for {ruleset.name} </h3>
                    <p>To get started you can click the Create Campaign button above. From there you will be able to name and add a description of the campaign. Once created you will be given an invitation link that you can send out to your players so they can join with the characters they have created or create new ones for this campaign.</p>
                </Col>
            </Row>
        </Card.Body>
    </Card>
}


Card.HeaderImage = function CardHeaderImage({height=150, width="100%", src}) {
    return <div style={{
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        height: height,
        width: width
    }}></div>
}

export default function Campaigns() {
    const {ruleset} = useContext(RulesetContext)
    const {addNotice} = useContext(NoticeContext)
    const {getCampaigns} = useContext(ClientContext)
    const [campaigns, setCampaigns] = useState([])

    function refreshCampaigns() {
        getCampaigns(ruleset).then(campaigns => {
            setCampaigns(campaigns)
        })
    }

    useEffect(() => {
        if (ruleset) {
            refreshCampaigns()
        } else {
            addNotice("Error","Select a ruleset above to view your campaigns.", "danger")
        }
    }, [ruleset])


    return <Container className={"mt-4"}>
        <Container className={"justify-content-between"}>
            <Row>
                <Col>
                    <h1>My Campaigns</h1>
                </Col>
                <Col className={"d-flex flex-column align-items-end"}>
                    { ruleset?.id && <NavLink className="btn btn-primary" to={"create"} replace={true}>Create a Campaign</NavLink>}
                </Col>
            </Row>
        </Container>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <Container fluid className={"d-flex flex-row flex-wrap"}>
            {campaigns.length > 0 && campaigns.map((campaign) => <CampaignCard key={`campaign-${campaign.id}`} campaign={campaign} refreshCampaigns={refreshCampaigns}/>)}
            {campaigns.length === 0 && <EmptyList/>}
        </Container>
    </Container>
}