import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import RulesetContext from "services/RulesetContext"
import React, {useContext} from "react"
import { useLocation } from "react-router-dom"

export default function RuleSetSelector() {
    const {ruleset, rulesets} = useContext(RulesetContext)
    const location = useLocation()

    return <DropdownButton align="end" style={{zIndex: 1050}} variant="outline-light" id="dropdown-ruleset" title={ruleset?.name || "Change Rule Set"}>
        {rulesets.map((_ruleset) => {
            return <Dropdown.Item key={`selector-${_ruleset.id}`} disabled={!_ruleset.active} onClick={() => {
                let path = location.pathname
                localStorage.setItem("ruleset", JSON.stringify(_ruleset))
                if (path.split("/").indexOf("character") === 1 && path.split("/").length > 2) {
                    path = "/characters"
                }
                window.location.assign(`${_ruleset.hostname}${path}`)
            }}>{_ruleset.name} {_ruleset.active ? "" : <span className={"text-danger"}>Coming Soon!</span>}</Dropdown.Item>
        })}
    </DropdownButton>
}