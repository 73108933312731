import React, {createContext, useEffect, useContext, useMemo, useState} from "react"
import { loadStripe } from "@stripe/stripe-js"
import ClientContext from "./ajax"

const StripeContext = createContext(null)

const publicKeys = {
    "test": "pk_test_51N3WCzAubu6HdtSz8RJdhAthW7fIlS9XX2FlXTEEJfPJZZuVVMO6bULNaeTzBbQvO0Bz9qxGuhuYhMzJDAthjYLT00ex0tV8yc",
    "live": "pk_live_51N3WCzAubu6HdtSz7beC2jfkuvdE7P6iPtrYhTz6nKfiMl1qloQdg77xg42RIlboRXXrYYMrt77roMyMAevkyI8L00csQckQat"
}

export function StripeContextProvider(props) {
    const [stripePromise, setStripePromise] = useState(false)
    const [clientSecret, setClientSecret] = useState(false)
    const {getUserSubscription, getStripeMode} = useContext(ClientContext)
    const [mode, setMode] = useState()
    const [subscriptions, setSubscriptions] = useState(null)

    const loadSubscriptions = () => {
        getUserSubscription().then((subscriptions) => {
            setSubscriptions(subscriptions)
        })
    }

    useEffect(() => {
        getStripeMode().then((mode) => {
            setMode(mode)
        })
    }, [])

    useEffect(() => {
        if (clientSecret) {
            setStripePromise(loadStripe(clientSecret))
        }
    }, [clientSecret])

    useEffect(() => {
        if (mode) {
            setClientSecret(publicKeys[mode])
        }
    }, [mode])

    const value = useMemo(() => ({
        mode,
        setMode,
        stripePromise,
        subscriptions,
        loadSubscriptions
    }), [mode, stripePromise, subscriptions])

    return <StripeContext.Provider value={value} {...props}>
    </StripeContext.Provider>
}

export default StripeContext