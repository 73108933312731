import React, { useContext, useState, useEffect, useRef } from "react"
import { ListGroup } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"
import RulesetContext from "services/RulesetContext"
import ClientContext from "services/ajax"


export default function SwitchList({ data, sectionKey, keys, renderer}) {
    const mounted = useRef(false)
    const {character, updateCharacter } = useContext(CharacterContext)
    const {ruleset} = useContext(RulesetContext)
    const ajax = useContext(ClientContext)
    const [items, setItems] = useState([])

    const [selectedItems, setSelectedItems] = useState(character?.[sectionKey]?.[keys] || [])

    useEffect(() => {
        mounted.current = true
        const [source, method] = data.split(".")
        if (source === "ajax" && ruleset?.id) {
            ajax[method](ruleset.id).then((items) => {
                console.log(items)
                if (!mounted.current) return
                setItems(items)
                if (character?.[sectionKey]?.[keys]?.length > 0) {
                    setSelectedItems(character?.[sectionKey]?.[keys])
                } else {
                    setSelectedItems([items[0]])
                }
            }).catch((e) => {
                console.error(e)
            })
        }
        return () => {
            mounted.current = false
        }
    }, [])

    useEffect(() => {
        const patch = {}
        if (selectedItems.length >= 1) {
            patch[keys] = selectedItems
            updateCharacter(character, sectionKey, patch)
        }
    }, [selectedItems])

    return <ListGroup>
        {(items || []).map(renderer(selectedItems, setSelectedItems))}
    </ListGroup>
}