import React, {useContext} from "react"
import UserContext from "services/UserContext"
import {Navigate} from "react-router-dom"

const RouteGuard = ({component: Component, level = "", ...rest }) => {
    const {user} = useContext(UserContext)
    const meetsLevel = user?.roles?.includes(level) || level === ""
    if (user && meetsLevel) {
        return <Component {...rest} />
    } else {
        return <Navigate to="/login" replace={true}/>
    }
}

export default RouteGuard