import React, {createContext, useContext, useEffect, useState, useMemo} from "react"
import CharacterContext from "services/CharacterContext"

const CurrencyContext = createContext(null)

export function CurrencyContextProvider(props) {
    const {character, updateCharacter} = useContext(CharacterContext)
    const [gp, setGp] = useState(character?.inventory?.gp || 0)
    const [sp, setSp] = useState(character?.inventory?.sp || 0)
    const [cp, setCp] = useState(character?.inventory?.cp || 0)

    const parseCost = (cost) => {
        const parts = cost.split(" ")
        const matcher = /(\d+)(gp|sp|cp)/
        return parts.reduce((acc, part) => {
            if(matcher.test(part)) {
                const [, amount, currency] = part.match(matcher)
                acc[currency] += parseInt(amount, 10)
            }
            return acc
        }, {gp: 0, sp: 0, cp: 0})
    }

    const serializeCost = (cost) => {
        const out = []
        if (cost.gp > 0) {
            out.push(`${cost.gp}gp`)
        }
        if (cost.sp > 0) {
            out.push(`${cost.sp}sp`)
        }
        if (cost.cp > 0) {
            out.push(`${cost.cp}cp`)
        }
        return out.join(" ")
    }

    const reduceCurrency = (gp = 0, sp = 0, cp = 0) => {
        let _gp = gp
        let _sp = sp
        let _cp = cp
        if (_cp > 10) {
            _sp += Math.floor(_cp / 10)
            _cp -= 10 * Math.floor(_cp / 10)
        }
        if (_sp > 50) {
            _gp += Math.floor(_sp / 50)
            _sp -= 50 * Math.floor(_sp / 50)
        }
        return {gp: parseInt(_gp || 0, 10), sp: parseInt(_sp || 0, 10), cp: parseInt(_cp || 0, 10)}
    }

    useEffect(() => {
        if (character?.inventory) {
            setGp(character.inventory.gp || 0)
            setSp(character.inventory.sp || 0)
            setCp(character.inventory.cp || 0)
        }
    }, [character])

    useEffect(() => {
        if (!isNaN(gp) || !isNaN(sp) || !isNaN(cp)) {
            updateCharacter(character, "inventory", {gp, sp, cp})
        }
    }, [gp, sp, cp])

    const setCurrent = ({gp, sp, cp}) => {
        setGp(gp)
        setSp(sp)
        setCp(cp)
    }

    const totalCost = (items) => {
        return items.reduce((acc, item) => {
            const cost = parseCost(item.cost)
            acc.gp += cost.gp * (item.qty || 1)
            acc.sp += cost.sp * (item.qty || 1)
            acc.cp += cost.cp * (item.qty || 1)
            return acc
        }, {gp: 0, sp: 0, cp: 0})
    }

    async function spendCurrency(costStr) {
        return new Promise((resolve, reject) => {
            const cost = parseCost(costStr)
            let asCp = 0
            asCp = cost.gp * 500
            asCp += cost.sp * 10
            asCp += cost.cp
            const canSpend = gp * 500 + sp * 10 + cp
            if (canSpend - asCp < 0) {
                reject("Not enough money!")
            } else {   
                resolve(reduceCurrency(0, 0, (canSpend - asCp || 0)))
            }
        })
    }

    const value = useMemo(() => {
        return {current: {gp, sp, cp}, setCurrent, spendCurrency, setCp, setSp, setGp, parseCost, serializeCost, totalCost}
    }, [gp, sp, cp])

    return <CurrencyContext.Provider value={value} {...props}></CurrencyContext.Provider>
}

export default CurrencyContext