import React from "react"
import Container from "react-bootstrap/Container"
const TermsOfServicePage = () => {
    return (<Container className="mt-4">
        <div>
            <h1>Terms of Service</h1>
            <p>Welcome to OSR Beyond, a platform for creating and managing OSR characters and campaigns. By using our web application, you agree to be bound by the following terms of service. If you do not agree to these terms, please do not use our service.</p>
            <h2>User accounts</h2>
            <p>In order to use our web application, you must create a user account. You agree to provide accurate and complete information when creating your account, and to keep your account information up to date. You are responsible for maintaining the confidentiality of your account login information, and for all activities that occur under your account.</p>
            <h2>User-generated content</h2>
            <p>Our web application allows you to create and upload content, including images and character information. You retain ownership of any content that you create, but by uploading it to our service, you grant us a non-exclusive, royalty-free license to use, display, and distribute that content in connection with our service.</p>
            <p>You are solely responsible for the content that you create and upload to our service. You agree not to upload any content that is illegal, infringes on the intellectual property rights of others, or is otherwise offensive or inappropriate.</p>
            <h2>Intellectual property</h2>
            <p>We respect the intellectual property rights of others, and we expect our users to do the same. If you believe that your intellectual property rights have been infringed by content on our web application, please contact us at terms@osrbeyond.com and we will investigate your claim.</p>
            <h2>User conduct</h2>
            <p>You agree to use our web application in a manner that is lawful and consistent with these terms of service. You agree not to use our service to engage in any activity that is illegal or harmful to others.</p>
            <h2>Limitation of liability</h2>
            <p>Our web application is provided on an &quot;as is&quot; basis, without any warranties or guarantees of any kind. We will not be liable for any damages of any kind arising from your use of our service.</p>
            <h2>Changes to the terms of service</h2>
            <p>We may update these terms of service from time to time, and we will notify you of any changes by posting them on our web application. Your continued use of our service after any changes to these terms will constitute your acceptance of those changes.</p>
            <h2>Termination</h2>
            <p>We reserve the right to terminate your user account and access to our web application at any time and for any reason, without notice.</p>
            <h2>Governing law</h2>
            <p>These terms of service are governed by and construed in accordance with the laws of British Columbia Canada. Any disputes arising from your use of our web application will be resolved in the courts of British Columbia Canada.</p>
            <p>Thank you for using OSR Beyond. If you have any questions or concerns about these terms of service, please contact us at <a href="mailto:terms@osrbeyond.com">terms@osrbeyond.com</a>.</p>
        </div>
    </Container>)
}

export default TermsOfServicePage
