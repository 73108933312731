import React, {useState, useContext} from "react"
import { Container, InputGroup, Form, Button } from "react-bootstrap"
import UserContext from "services/UserContext"
import ClientContext from "services/ajax"
import NoticeContext from "services/NoticeContext"

export default function SubmitRuleset() {
    const [message, setMessage] = useState("")
    const {user} = useContext(UserContext)
    const {sendFeedback} = useContext(ClientContext)
    const {addNotice} = useContext(NoticeContext)

    return <Container className="mt-4">
        <h1 className={"brand"}>Send us Feedback</h1>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <InputGroup className="mt-3">
            <Form.Control value={message} rows={10} onChange={({target}) => {
                setMessage(target.value)
            }} type='text' as="textarea"></Form.Control>
        </InputGroup>
        <Button className="mt-3" onClick={() => {
            if (message) {
                sendFeedback(user.email, message).then(() => {
                    addNotice("Request Sent", "Thank you for sending us this feedback. We will reach out if we need more info!","success")
                    setMessage("")
                })
            }
        }}>Send Feedback</Button>
    </Container>
}