import React, {useContext, useEffect} from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "App.css"
import Home from "pages/Home"
import Characters from "pages/Characters"
import Campaigns from "pages/Campaigns"
import Splash from "pages/Splash"
import CampaignBuilder from "pages/CampaignBuilder"
import CampaignEditor from "pages/CampaignEditor"
import CharacterBuilder from "pages/CharacterBuilder"
import CharacterViewer from "pages/CharacterViewer"
import Register from "pages/Register"
import Profile from "pages/Profile"
import JoinCampaign from "pages/JoinCampaign"
import Admin from "pages/Admin"
import ForgotPassword from "pages/ForgotPassword"
import Feedback from "pages/Feedback"
import SubmitRuleset from "pages/SubmitRuleset"
import Subscribe from "pages/Subscribe"
import RouteGuard from "components/RouteGuard"
import Header from "components/Header"
import Navbar from "react-bootstrap/Navbar"
import Container from "react-bootstrap/Container"
import ThemeProvider from "react-bootstrap/ThemeProvider"
import { Beyond20ContextProvider } from "services/Beyond20Context"
import { DiceProvider } from "services/DiceContext"
import {IsSavingContext, IsSavingProvider} from "services/ajax"
import {
    createBrowserRouter,
    RouterProvider,
    Link,
    useNavigate,
} from "react-router-dom"
import ErrorPage from "pages/Error"
import UserContext, {UserContextProvider} from "services/UserContext"
import Rules from "pages/Rules"
import { RulesetContextProvider } from "services/RulesetContext"
import {NoticeContextProvider} from "services/NoticeContext"
import ScrollToTop from "react-scroll-to-top"
import ClientContext, { ClientProvider } from "services/ajax"
import CampaignViewer from "pages/CampaignViewer"
import { CharacterContextProvider } from "services/CharacterContext"
import { StripeContextProvider } from "services/StripeContext"
import TermsOfServicePage from "pages/TermsOfService"
import { ErrorBoundary } from "react-error-boundary"


const router = createBrowserRouter([
    {
        path: "/",
        element: <LoginLayout><Splash/></LoginLayout>
    },
    {
        path: "/login",
        element: <LoginLayout><Register path="/login"/></LoginLayout>
    },
    {
        path: "/register",
        element: <LoginLayout><Register path="/register"/></LoginLayout>
    },
    {
        path: "/forgot-password",
        element: <LoginLayout><ForgotPassword/></LoginLayout>
    },
    {
        path: "/reset-password/:token",
        element: <LoginLayout><ForgotPassword/></LoginLayout>
    },
    {
        path: "/welcome",
        element: <AppLayout><Home/></AppLayout>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/characters",
        element: <AppLayout><RouteGuard path="/characters" component={Characters}/></AppLayout>
    },
    {
        path: "/feedback",
        element: <AppLayout><RouteGuard path="/feedback" component={Feedback}/></AppLayout>
    },
    {
        path: "/campaign",
        element: <AppLayout><RouteGuard path="/campaign" component={Campaigns}/></AppLayout>
    },
    {
        path: "/campaign/join/:slug",
        element: <AppLayout><RouteGuard path="/campaign/join/:slug" component={JoinCampaign} /></AppLayout>
    },
    {
        path: "/profile",
        element: <AppLayout><RouteGuard path="/profile" component={Profile}/></AppLayout>
    },
    {
        path: "/characters/builder/:system?/:build?/:character_id?/:step?",
        element: <AppLayout><CharacterBuilder/></AppLayout>
    },
    {
        path: "/campaign/create",
        element: <AppLayout><CampaignBuilder/></AppLayout>
    },
    {
        path: "/character/:character_id/:tab?",
        element: <AppLayout><CharacterViewer/></AppLayout>
    },
    {
        path: "/campaign/:slug",
        element: <AppLayout><CampaignViewer/></AppLayout>
    },
    {
        path: "/campaign/:slug/edit",
        element: <AppLayout><CampaignEditor/></AppLayout>
    },
    {
        path: "/rules/:chapter?",
        element: <AppLayout><Rules/></AppLayout>
    },
    {
        path: "/admin/:subpage?/:id?",
        element: <AppLayout><RouteGuard path="/admin(/:subpage)" level={"administrator"} component={Admin}/></AppLayout>
    },
    {
        path: "/submit-ruleset",
        element: <AppLayout><SubmitRuleset/></AppLayout>
    },
    {
        path: "/subscribe",
        element: <AppLayout><StripeContextProvider><Subscribe/></StripeContextProvider></AppLayout>
    },
    {
        path: "/subscribed",
        element: <AppLayout><StripeContextProvider><Subscribe/></StripeContextProvider></AppLayout>
    },
    {
        path: "/tos",
        element: <AppLayout><TermsOfServicePage/></AppLayout>
    },
], {
    v7_normalizeFormMethod: true
})

function IsSaving({isSaving}) {
    return <div className={`${isSaving ? "d-block" : "d-none"} position-fixed bottom-0 end-0 p-3 is-saving`} style={{zIndex: 1040}}>
        <i className={"bi-arrow-repeat"} />
    </div>
}


function Footer() {
    const {isSaving} = useContext(IsSavingContext)
    return <Navbar className='mt-5' variant='dark' bg="dark" fixed="bottom">
        <Container className="d-flex justify-content-between text-light small">
            OSRBeyond &#169; 2023 Quest Giver Games LTD <IsSaving isSaving={isSaving}/>
            <Link to="/tos" className="text-white" replace={true}>Terms of Service</Link>
        </Container>
    </Navbar>
}

function LoginLayout({children}) {
    return <div>
        {children}
    </div>
}

function AppLayout ({children}) {
    const {logoutUser} = useContext(ClientContext)
    const {user, loading} = useContext(UserContext)
    const navigate = useNavigate()
    useEffect(() => {
        if (!user && !loading) {
            navigate("/login")
        }
    }, [user, loading])
    if (!user && !loading) {
        logoutUser()
    }
    if (loading) return <>Loading</>
    return <>
        <Header/>
        <Container fluid className="vstack gap-3 pb-5 mb-5">
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        </Container>
        <ScrollToTop smooth/>
        <Footer/>
    </>
}

function App() {
    return (
        <ThemeProvider value="dark">
            <Beyond20ContextProvider>
                <NoticeContextProvider>
                    <IsSavingProvider>
                        <ClientProvider>
                            <UserContextProvider>
                                <RulesetContextProvider>
                                    <DiceProvider>
                                        <CharacterContextProvider>
                                            <RouterProvider router={router}/>
                                        </CharacterContextProvider>
                                    </DiceProvider>
                                </RulesetContextProvider>
                            </UserContextProvider>
                        </ClientProvider>
                    </IsSavingProvider>
                </NoticeContextProvider>
            </Beyond20ContextProvider>
        </ThemeProvider>
    )
}

export default App
