export function capitalCase(str) {
    return str[0].toUpperCase() + str.slice(1)
}

export function ordinalSuffixOf(i) {
    const j = i % 10,
        k = i % 100
    if (j == 1 && k != 11) {
        return i + "st"
    }
    if (j == 2 && k != 12) {
        return i + "nd"
    }
    if (j == 3 && k != 13) {
        return i + "rd"
    }
    return i + "th"
}

export function signedPrefix(number) {
    return number > 0 ? `+${number}` : number
}

export function decorateCurrency(number) {
    return `$${(number / 100).toFixed(2)}`
}

export function getImagePath(image){
    try {
        return `/api/image/_d/${image.owner_id}/${image.id}.${image.original_name.split(".").slice(-1)[0]}`
    } catch (e) {
        console.error(e)
        return "about:blank"
    }
}

export function standardDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
