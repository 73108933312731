import React, {useState, useContext} from "react"
import Button from "react-bootstrap/Button"
import {Container, Row, Col} from "react-bootstrap"
import Form from "react-bootstrap/Form"
import NoticeContext from "services/NoticeContext"
import ClientContext from "services/ajax"
import whoweare from "../assets/whoweare4.jpg"
import { Navigate } from "react-router-dom"

export default function ForgotPassword() {
    const {email, token} = window.location.search.slice(1).split("&").reduce((m, part)=> {
        const [key, val] = part.split("=")
        m[key] = val
        return m
    }, {})
    const [stateEmail, setEmail] = useState(email)
    const [password1, setPassword1] = useState()
    const [password2, setPassword2] = useState()
    const [loading, setLoading] = useState(false)
    const {addNotice} = useContext(NoticeContext)
    const { forgotPassword, resetPassword } = useContext(ClientContext)
    const [resetSuccess, setResetSuccess] = useState(false)

    if (resetSuccess) {
        return <Navigate to={"/login"} />
    }
    return <Container fluid>
        <Row>
            <Col style={{
                backgroundImage: `url(${whoweare})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100vh"
            }}>
            </Col>
            <Col>
                <Row className={"h-25"}>

                </Row>
                <Row>
                    <Col>
                        {!token && <Container fluid>
                            <div className="vstack gap-3 lonely">
                                <h1 className={"brand"}>Forgot Password:</h1>
                                <Form.Group>
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control type="email" onChange={({target}) => setEmail(target.value)}></Form.Control>
                                </Form.Group>
                                <div className="hstack gap-2">
                                    <Button disabled={(!stateEmail) || loading} onClick={() => {
                                        setLoading(true)
                                        forgotPassword(stateEmail).then(() => {
                                            addNotice({type: "success", message: "Check your email for furhter instruction."})
                                        }).catch(({message}) => {
                                            addNotice({type: "danger", message})
                                        }).finally(() => {
                                            setLoading(false)
                                        })
                                    }}>Request</Button>
                                </div>
                            </div>
                        </Container>}
                        {token &&  <Container fluid>
                            <div className="vstack gap-3 lonely">
                                <h1 className={"brand"}>Reset Password:</h1>
                                <Form.Group>
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control type="password" onChange={({target}) => setPassword1(target.value)}></Form.Control>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Confirm:</Form.Label>
                                    <Form.Control type="password" onChange={({target}) => setPassword2(target.value)}></Form.Control>
                                </Form.Group>
                                <div className="hstack gap-2">
                                    <Button disabled={!(password1 && password2) || loading} onClick={() => {
                                        setLoading(true)
                                        resetPassword(email, password1, password2, token).then(({message, status}) => {
                                            if (status === "error") {
                                                addNotice({type: "danger", message})
                                            } else {
                                                setResetSuccess(true)
                                            }
                                            setLoading(false)
                                        })
                                    }}>Submit</Button>
                                </div>
                            </div>
                        </Container>}   
                    </Col>                    
                </Row>
            </Col>
        </Row>
    </Container>
}