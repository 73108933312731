import React, { useContext, lazy, Suspense } from "react"
import { Navigate } from "react-router-dom"
import { Placeholder } from "react-bootstrap"
import CharacterContext, {CharacterContextProvider} from "services/CharacterContext"
import RulesetContext from "services/RulesetContext"
import ErrorBoundary from "components/ErrorBoundary"

const FallbackComponent = () => {
    const {character} = useContext(CharacterContext)
    return <Navigate to={`/characters/builder/${character.ruleset_id}/standard/${character.id}/0`} replace={true} />
}

export default function CharacterViewer() {
    const {ruleset} = useContext(RulesetContext)

    const Component = lazy(() => import(`../components/${ruleset.id}/CharacterSheet`).catch(
        (error) => {
            console.error("Component Failed Loading:", error)
            return { default: FallbackComponent }
        }
    ))

    return <CharacterContextProvider>
        <ErrorBoundary fallback={FallbackComponent}>
            <Suspense fallback={<Placeholder/>}>
                <Component/>
            </Suspense>
        </ErrorBoundary>
    </CharacterContextProvider>
}