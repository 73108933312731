
import React, { useContext, useState, useEffect } from "react"
import {Container, ListGroup, Row, Col, Button, Form, Accordion, InputGroup, Modal} from "react-bootstrap"
import {SimpleDiceRoller} from "components/DiceRoller"
import CurrencyContext, {CurrencyContextProvider} from "./Inventory/CurrencyContext"
import InventoryContext, {InventoryContextProvider} from "./Inventory/InventoryContext"

export function InventoryCurrencyProvider({children}) {
    return <CurrencyContextProvider>
        <InventoryContextProvider>
            {children}
        </InventoryContextProvider>
    </CurrencyContextProvider>
}

export function ItemTemplate({item, context, onBuy}) {
    return <ListGroup.Item className={(item?.cost?.[context] || !context) ? "" : "d-none"}>
        <Row>
            <Col xs={4}>{item?.name ? `${item.name} (${item.type})` : item?.type}</Col>
            <Col xs={4}>{context ? item?.cost?.[context] : item?.ac ? `AC ${item?.ac}` : item?.damage ? `Dmg ${item?.damage}` : ""}</Col>
            <Col xs={2}>{item?.encumberance}</Col>
            {!context && <Col xs={2}>{item?.qty || 1}</Col>}
            {context && <Col xs={2} className="d-flex gap-2">
                <Button className={"btn-sm"} onClick={() => onBuy(false)}>Buy <i className="bi-plus"/></Button>
                <Button variant="danger" className={"btn-sm"} onClick={() => onBuy(true)}>Take <i className="bi-plus"/></Button></Col>
            }
        </Row>
    </ListGroup.Item>
}

export function StowModal({containers, show, onHide, onSelect}) {
    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>Stow Item</Modal.Header>
        <Modal.Body>
            {containers.map((container) => {
                return <Button key={container.id} onClick={() => onSelect(container)}>{container.type}</Button>
            })}
        </Modal.Body>
    </Modal>
}

export function EditItemModal({item, show, onHide}) {
    const {categories, saveItem, addItem} = useContext(InventoryContext)
    const [name, setName] = useState(item?.name || "")
    const [type, setType] = useState(item?.type || "")
    const [category, setCategory] = useState(item?.category || "")
    const [cost, setCost] = useState(item?.cost || {})
    const [ac, setAc] = useState(item?.ac || "")
    const [damage, setDamage] = useState(item?.damage || "")
    const [encumberance, setEncumberance] = useState(item?.encumberance || "")

    useEffect(() => {
        setName(item?.name)
        setType(item?.type)
        setCategory(item?.category)
        setCost(item?.cost)
        setAc(item?.ac)
        setDamage(item?.damage)
        setEncumberance(item?.encumberance)
    }, [item])

    const hideHandler = () => {
        setName("")
        setType("")
        setCategory("")
        setCost({})
        setAc("")
        setDamage("")
        setEncumberance("")
        onHide()
    }

    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control type="text" value={type} onChange={(e) => setType(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <Form.Control as="select" value={category} onChange={(e) => setCategory(e.target.value)}>
                        <option value={""}>None</option>
                        {Array.from(categories).map((category) => {
                            return <option key={category} value={category}>{category}</option>
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Rural Cost</Form.Label>
                    <Form.Control className={"w-25"} type="text" value={cost?.["rural"]} onChange={({target}) => setCost({...cost, rural: target.value})}/>
                    <Form.Label>City Cost</Form.Label>
                    <Form.Control className={"w-25"} type="text" value={cost?.["city"]} onChange={({target}) => setCost({...cost, city: target.value})}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>AC</Form.Label>
                    <Form.Control type="text" value={ac} onChange={(e) => setAc(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Damage</Form.Label>
                    <Form.Control type="text" value={damage} onChange={(e) => setDamage(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Encumberance</Form.Label>
                    <Form.Control as="select" value={encumberance} onChange={(e) => setEncumberance(e.target.value)}>
                        <option value={"None"}>None</option>
                        <option value={"Normal"}>Normal</option>
                        <option value={"Oversized"}>Oversized</option>
                    </Form.Control>
                </Form.Group>
                <Button onClick={() => {
                    if (item?.id) {
                        saveItem({...item, name, type, category, cost, ac, damage, encumberance}).then(hideHandler)
                    } else {
                        addItem({name, type, category, cost, ac, damage, encumberance}).then(hideHandler)
                    }
                }}>Save</Button>
            </Form>
        </Modal.Body>
    </Modal>

}

export function CharacterInventory() {
    const {items, removeItem, equipItem, stowItem, addItem, itemsByContainer, setHideCart} = useContext(InventoryContext)
    const {current: {gp, sp, cp}, setGp, setSp, setCp} = useContext(CurrencyContext)
    const [show, setShow] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showCurrency, setShowCurrency] = useState(false)
    const [toStow, setToStow] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [showItemModal, setShowItemModal] = useState(false)

    useEffect(() => {
        setHideCart(false)
    }, [showModal])

    return <>
        <Row className="text-start mt-2">
            <Col xs={12}><h3 className="brand">Currency</h3></Col>
            <Col xs={3}><h3 className="brand">{gp || 0} gp</h3></Col>
            <Col xs={3}><h3 className="brand">{sp || 0} sp</h3></Col>
            <Col xs={3}><h3 className="brand">{cp || 0} cp</h3></Col>
            <Col xs={3}><Button onClick={() => setShowCurrency(true)}>Edit</Button></Col>
        </Row>
        <hr className="border border-primary border-1"></hr>
        <Modal show={showCurrency} onHide={() => setShowCurrency(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Update Currency</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Gold</Form.Label>
                        <Form.Control type="number" value={gp} onChange={(e) => setGp(parseInt(e.target.value, 10))}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Silver</Form.Label>
                        <Form.Control type="number" value={sp} onChange={(e) => setSp(parseInt(e.target.value, 10))}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Copper</Form.Label>
                        <Form.Control type="number" value={cp} onChange={(e) => setCp(parseInt(e.target.value, 10))}></Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowCurrency(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <StowModal show={show} onHide={() => setShow(false)} containers={(items||[]).filter(({category}) => {
            return ["Containers", "Vehicles", "Animals"].includes(category)
        })} onSelect={(container) => {
            stowItem(toStow, container)
            setToStow(null)
            setShow(false)
        }}></StowModal>
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Inventory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CharacterItems/>
            </Modal.Body>
        </Modal>
        {itemsByContainer && Object.entries(itemsByContainer).map(([key, value]) => {
            return <Container key={key}>
                <h4 className={"brand"}>{value.type}</h4> 
                {value.type === "Inventory" && <><Button onClick={() => {setShowModal(true)}}>Manage Inventory</Button> <Button onClick={() => {setShowItemModal(true)}}>Add Custom Item</Button></>}
                <ListGroup>
                    <ListGroup.Item className="border-bottom border-bottom-1 border-primary">
                        <Row>
                            <Col xs={2}><h6 className="brand">Equip</h6></Col>
                            <Col xs={4}><h6 className="brand">Item</h6></Col>
                            <Col xs={1}><h6 className="brand">Qty</h6></Col>
                            <Col xs={1}><h6 className="brand">Enc</h6></Col>
                            <Col xs={4}><h6 className="brand">Action</h6></Col>                    
                        </Row>
                    </ListGroup.Item>
                    {Object.entries(value.contents).map(([id, item]) => {
                        return <ListGroup.Item key={id}>
                            <Row key={item.type}>
                                <Col xs={2}>{["Melee Weapon", "Missile Weapon","Armor","Containers"].includes(item.category) && <Form.Check type="checkbox" checked={item.equipped || false} onChange={() => {
                                    equipItem(item, !item.equipped)
                                }}></Form.Check>}</Col>
                                <Col xs={4}>{item?.name ? `${item.name} (${item.type})` : item?.type}</Col>
                                <Col xs={1}>{item.qty || 1}</Col>
                                <Col xs={1}>{item.encumberance}</Col>
                                <Col xs={4} className="d-flex flex-wrap">
                                    {!item.container && <Button variant="primary" title="Stow"  size="sm" onClick={() => {
                                        setToStow(item)
                                        setShow(true)
                                    } }>
                                        <i className={"bi-bag-plus"} />
                                    </Button>}
                                    {item.container && <Button variant="primary" title="Unstow" size="sm" onClick={() => {
                                        stowItem(item, null)
                                    }}><i className="bi-bag-dash"/></Button>}
                                    <Button variant="warning" title="Edit" size="sm" onClick={() => { 
                                        setCurrentItem(item)
                                        setShowItemModal(true)
                                    }}><i className={"bi-pen"} /></Button>
                                    <Button variant="danger" title="Remove" size="sm" onClick={() => { 
                                        removeItem(item)
                                    }}><i className={"bi-dash"} /></Button>
                                    <Button variant="success" title="Add" size="sm" onClick={() => {
                                        addItem(item)
                                    }}><i className="bi-plus"/></Button>
                                </Col>
                            </Row>
                        </ListGroup.Item>})}
                </ListGroup>    
                <EditItemModal item={currentItem} show={showItemModal} onHide={() => setShowItemModal(false)}/>
            </Container>
        })}
    </>
}

export default function CharacterItems() {
    const {items, market, marketType, setMarketType, purchaseItem, chaChingSound} = useContext(InventoryContext)
    const {current: {gp, sp, cp}, setCp, setSp, setGp} = useContext(CurrencyContext)

    return <Container>
        <h1 className="brand">Inventory</h1>
        <SimpleDiceRoller label='Roll Starting SP' d='3d6' onResult={(result) => {
            const total = result.reduce((acc, {value}) => acc + value, 0)
            setSp(total * 10)
        }} />
        <InputGroup>
            <InputGroup.Text className={"w-50"}>Gold Pieces:</InputGroup.Text>
            <Form.Control value={gp || 0} onChange={({target}) => { setGp(parseInt(target.value, 10)) }} type="number"></Form.Control>
        </InputGroup>
        <InputGroup>
            <InputGroup.Text className={"w-50"}>Silver Pieces:</InputGroup.Text>
            <Form.Control value={sp || 0} onChange={({target}) => { setSp(parseInt(target.value, 10)) }} type="number"></Form.Control>
        </InputGroup>
        <InputGroup>
            <InputGroup.Text className={"w-50"}>Copper Pieces:</InputGroup.Text>
            <Form.Control value={cp || 0} onChange={({target}) => { setCp(parseInt(target.value, 10)) }} type="number"></Form.Control>
        </InputGroup>
        <ListGroup>
            <ListGroup.Item>
                <Row>
                    <Col xs={4}>Item</Col>
                    <Col xs={4}>AC/Dmg</Col>
                    <Col xs={2}>Enc</Col>
                    <Col xs={2}>Qty</Col>
                </Row>
            </ListGroup.Item>
            {items?.map((item, i) => {
                return <ItemTemplate key={`items-${i}`} item={item} />
            })}
        </ListGroup>
        <Form.Label>City / Rural</Form.Label>
        <Form.Check type="switch" id="custom-switch" checked={marketType === "city"} onChange={() => {
            setMarketType(marketType === "city" ? "rural" : "city")
        }} />
        <Accordion>
            {Object.entries((market || {})).map(([category, categoryItems]) => {
                return <Accordion.Item eventKey={category} key={category}>
                    <Accordion.Header>{category}</Accordion.Header>
                    <Accordion.Body>
                        <ListGroup>
                            {categoryItems.map((item, i) => {
                                return <ItemTemplate key={`available-${i}`} item={item} context={marketType} onBuy={(free) => {
                                    if (!free) {
                                        chaChingSound()
                                    }
                                    purchaseItem(item, free)
                                }}/>
                            })}
                        </ListGroup> 
                    </Accordion.Body>
                </Accordion.Item>
            })}
        </Accordion>
    </Container>
}