import React, {useState, useContext} from "react"
import { Button, Container, FormGroup, Form } from "react-bootstrap"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import RulesetContext from "services/RulesetContext"
import ClientContext from "services/ajax"
import { Navigate } from "react-router-dom"

export default function CampaignBuilder() {
    const {ruleset} = useContext(RulesetContext)
    const [description, setDescription] = useState("")
    const [name, setName] = useState("")
    const {createCampaign} = useContext(ClientContext)
    const [newCampaignId, setNewCampaignId] = useState(null)

    if (newCampaignId) {
        return <Navigate to={`/campaign/${newCampaignId}`}/>
    }

    return <Container>
        <h1>Create a Campaign</h1>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <FormGroup>
            <Form.Label>Campaign Name *</Form.Label>
            <Form.Control type="text" value={name} onChange={({target}) => {setName(target.value)}} placeholder="Enter a name"></Form.Control>
        </FormGroup>
        <FormGroup>
            <Form.Label>Description</Form.Label>
            <ReactQuill theme="snow" value={description} onChange={setDescription}/>
        </FormGroup>
        <Button onClick={() => {
            createCampaign(ruleset, {name, description}).then((campaign) => setNewCampaignId(campaign.id))
        }}>Save</Button>
    </Container>
}