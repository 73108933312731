import React, {Component} from "react"

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = {hasError: false}
    }

    static getDerivedStateFromError(error) {
        return {hasError: true, message: error?.message, status: error?.status}
    }

    render() {
        function addExtraProps(Component, extraProps) {
            return <Component {...Component.props} {...extraProps} />
        }

        if (this.state.hasError) {
            return addExtraProps(this.props.fallback, {
                errorMessage: this.state.message,
                errorStatus: this.state.status
            })
        }

        return this.props.children
    }
}