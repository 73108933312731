import React, {useContext} from "react"
import defaultCover from "assets/campaign-cover3.jpg"
import { CharacterAvatar } from "components/Avatar"
import {Button, Card, Row, Col} from "react-bootstrap"
import UserContext from "services/UserContext"
import ClientContext from "services/ajax"
import { useMediaQuery } from "react-responsive"

export default function CampaignCard({campaign, refreshCampaigns}) {
    const {user} = useContext(UserContext)
    const {deleteCampaign} = useContext(ClientContext)
    const isDesktop = useMediaQuery({ query: "(min-width: 1224px)" })
    const displayDate = ((d) => {
        return `${d.getMonth() + 1}/${d.getDate()}/${d.getYear() + 1900}`
    })(new Date(Date.parse(campaign.created_at)))

    return <div className={`${isDesktop ? "w-50" : "w-100"} mt-2`} key={`campaign-${campaign.id}`}><Card >
        <div style={{
            backgroundImage: `url(${campaign?.cover || defaultCover})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "150px",
            width: "100%",
        }}></div>
        <Card.Body>
            <Row>
                <Col>
                    <div className={"text-secondary"}>Campaign Started {displayDate}</div>
                    {campaign?.characters?.map((character) => <CharacterAvatar lg className={"mx-1"} key={`character-${character.id}`} character={character} />)}
                </Col>
                <Col className='text-end'>
                    <h3>{campaign?.name}</h3>
                    <div>{campaign?.characters?.length} players</div>
                    <div>ROLE: {user.id === campaign?.owner_id ? "GAME MASTER" : "PLAYER"}</div>                
                    <Button className='ms-2' href={`/campaign/${campaign.slug}`}>View</Button>
                    {user.id === campaign.owner_id && <>
                        <Button className='ms-2' href={`/campaign/${campaign.slug}/edit`}>Edit</Button>
                        <Button className='ms-2' variant="danger" onClick={() => {
                            deleteCampaign(campaign).then(refreshCampaigns)
                        } }><i className='bi-trash' /></Button>
                    </>}
                </Col>
            </Row>
            
        </Card.Body>
    </Card></div>
}