import React, {useContext, useEffect, useState, useMemo} from "react"
import {SimpleDiceRoller} from "components/DiceRoller"
import { Container, Button, Col, Row, Form } from "react-bootstrap"
import Swappable from "components/Swappable"
import CharacterContext from "services/CharacterContext"
import { capitalCase } from "services/util"

export function AttributesTemplate({attributes, setters, rolling, input}) {
    return Object.entries(attributes).sort(([key1], [key2]) => key1 > key2 ? 1 : -1).map(([key, value]) => {
        return <Col xs={4} md={3} lg={2} className={"d-flex justify-content-center align-items-center flex-column"} key={key}>
            <h6 className={"brand d-flex justify-content-center align-items-center p-2 bg-white border border-1 border-primary rounded-4"}>{capitalCase(key)}</h6>
            {!input && <h2 className={"brand d-flex justify-content-center align-items-center p-2 bg-white border-top border-bottom border-2 border-primary rounded-2"} style={{marginTop: -10, marginBottom: -10}}>{value?.value}</h2>}
            {input && <Form.Control type="text" value={value?.value} onChange={(e) => { setters[key](e.target.value) }}/>}
            <h5 className={"brand bg-white border border-1 border-primary rounded-circle d-flex justify-content-center align-items-center"} style={{height: 40, width: 40, aspectRatio: 1}}>{value?.modifier}</h5>
            {rolling && <SimpleDiceRoller label="Roll" d="3d6" onResult={(results) => { 
                const total = results.reduce((acc, {value}) => acc + value, 0)
                setters[key](total)
            } } />}
        </Col>
    })
}

export function RollInfo({attributes}) {
    const attrsNotNull = () => {
        return attributes["charisma"] && attributes["constitution"] && attributes["dexterity"] && attributes["intelligence"] && attributes["strength"] && attributes["wisdom"]
    }

    const getTotalModifier = () => {
        return attrsNotNull() ? Object.values(attributes).reduce((m, n) => m + parseInt(n.modifier, 10), 0) : 0
    }

    const getTotalAttributes = () => {
        return attrsNotNull() ? Object.values(attributes).reduce((m, n) => m + parseInt(n.value, 10), 0) : 0
    }

    const subPar = attrsNotNull() && getTotalModifier() < 0

    return <>
        <Row className={`mt-3 pt-2 rounded-pill bg-black ${subPar ? "text-danger" : "text-white"}`}>
            <Col className={"d-flex justify-content-end"}><h4>Total Modifiers: {getTotalModifier()}</h4></Col>
            <Col className={"d-flex justify-content-start"}><h4>Total Roll: {getTotalAttributes()}</h4></Col>
        </Row>
    </>
}

CharacterAttributes.label = "Attributes"

export default function CharacterAttributes() {
    const {character, updateCharacter} = useContext(CharacterContext)

    const [state, setState] = useState("roll")
    const [attributes, setAttributes] = useState(character?.attributes)   
    const [charisma, setCharisma] = useState(character?.attributes?.charisma)
    const [constitution, setConstitution] = useState(character?.attributes?.constitution)
    const [dexterity, setDexterity] = useState(character?.attributes?.dexterity)
    const [intelligence, setIntelligence] = useState(character?.attributes?.intelligence)
    const [strength, setStrength] = useState(character?.attributes?.strength)
    const [wisdom, setWisdom] = useState(character?.attributes?.wisdom)
    const [swapsLeft, setSwapsLeft] = useState(character?.instance?.swapsLeft || 1)

    const modifier = (score) => {
        if (score <= 3) {
            return "-3"
        } else if (score < 6) {
            return "-2"
        } else if (score < 9) {
            return "-1"
        } else if (score < 13) {
            return "0"
        } else if (score < 16) {
            return "+1"
        } else if (score < 18) {
            return "+2"
        } else if (score < 21) {
            return "+3"
        }
    }

    useEffect(() => {
        if (state === "manual") {
            return
        }
        if (!(charisma && constitution && dexterity && intelligence && strength && wisdom)) {
            setState("roll")
        } else if (swapsLeft > 0) {
            if (state !== "swap") {
                setState("evaluate")
            }
        } else {
            setState("save")
        }
    }, [state])

    useEffect(() => {
        updateCharacter(character, "attributes", attributes)
    }, [attributes])

    useEffect(() => {
        updateCharacter(character, "instance", {swapsLeft: swapsLeft})
    }, [swapsLeft])

    useEffect(() => {
        setAttributes({
            charisma: charisma,
            constitution: constitution,
            dexterity: dexterity,
            intelligence: intelligence,
            strength: strength,
            wisdom: wisdom
        })
        if (charisma && constitution && dexterity && intelligence && strength && wisdom && state !== "manual") {
            setState("evaluate")
        }
    }, [charisma, constitution, dexterity, intelligence, strength, wisdom])

    const resetAttributes = () => {
        setCharisma(null)
        setConstitution(null)
        setDexterity(null)
        setIntelligence(null)
        setStrength(null)
        setWisdom(null)
        setAttributes({
            charisma: null,
            constitution: null,
            dexterity: null,
            intelligence: null,
            strength: null,
            wisdom: null
        })
        setSwapsLeft(1)
        setState("roll")
    }

    const wrapSetterWithModifiers = (setter) => {
        return (value) => {
            setter({value, modifier: modifier(value)})
        }
    }

    const setters = useMemo(() => ({
        charisma: wrapSetterWithModifiers(setCharisma),
        constitution: wrapSetterWithModifiers(setConstitution),
        dexterity: wrapSetterWithModifiers(setDexterity),
        intelligence: wrapSetterWithModifiers(setIntelligence),
        strength: wrapSetterWithModifiers(setStrength),
        wisdom: wrapSetterWithModifiers(setWisdom)
    }))

    return <Container>
        <h1 className={"brand"}>Attributes</h1>
        <Form.Switch label="Manual" checked={state === "manual"} onChange={() => {setState(state === "manual" ? "roll" : "manual")}}/>
        {state === "manual" && <Row>
            <AttributesTemplate attributes={{charisma, constitution, dexterity, intelligence, strength, wisdom}} setters={setters} rolling={false} input={true}/>
        </Row>}
        {state === "roll" && <>
            <Row className="pt-2 pb-3">
                <SimpleDiceRoller label="Roll Attributes" d="18d6" onResult={(result) => {
                    console.log(result)
                    wrapSetterWithModifiers(setCharisma)(result.slice(0, 3).reduce((m, n) => m + n.value, 0))
                    wrapSetterWithModifiers(setConstitution)(result.slice(3, 6).reduce((m, n) => m + n.value, 0))
                    wrapSetterWithModifiers(setDexterity)(result.slice(7, 9).reduce((m, n) => m + n.value, 0))
                    wrapSetterWithModifiers(setIntelligence)(result.slice(9, 12).reduce((m, n) => m + n.value, 0))
                    wrapSetterWithModifiers(setStrength)(result.slice(12, 15).reduce((m, n) => m + n.value, 0))
                    wrapSetterWithModifiers(setWisdom)(result.slice(15, 18).reduce((m, n) => m + n.value, 0))
                } } />
            </Row>
            <Row>
                <AttributesTemplate attributes={{charisma, constitution, dexterity, intelligence, strength, wisdom}} setters={setters} rolling={true}/>
            </Row>
        </>}
        {state === "evaluate" && <div >
            <Row className="pt-2 pb-3">
                <Col className="d-flex justify-content-center align-items-center">
                    <Button onClick={() => {
                        setAttributes({...attributes})
                        setSwapsLeft(0)
                        setState("save")
                    }}>Accept</Button>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                    <Button onClick={() => {setState("swap")}}>Swap</Button>
                </Col>
                <Col className="d-flex justify-content-center align-items-center">
                    <Button onClick={resetAttributes}>Reset</Button>
                </Col>
            </Row>
            <Row>
                <AttributesTemplate attributes={attributes} setters={setters}/>
            </Row>
            <RollInfo attributes={attributes} />
        </div>}
        {state === "swap" && <Swappable attributes={attributes} onSwap={(attributes) => {
            setSwapsLeft(swapsLeft - 1)
            setAttributes({...attributes})
            setState("evaluate")
        }}/>}
        {state === "save" && <>
            <Row className="pt-2 pb-3">
                <Button onClick={resetAttributes}>Reset</Button>
                <AttributesTemplate attributes={attributes} setters={setters}/>
            </Row>
            <RollInfo attributes={attributes} />
        </>}
    </Container>
}