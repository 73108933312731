import React, {useContext, useEffect, useState} from "react"
import CharacterContext from "services/CharacterContext"
import NoticeContext from "services/NoticeContext"
import {Button, Modal, Card, ListGroup, Row, Col} from "react-bootstrap"

export default function ShoppingCart({cart, setCart, CurrencyContext, InventoryContext}) {
    const [total, setTotal] = useState("0cp")
    const [itemCount, setItemCount] = useState(0)
    const {character, updateCharacter} = useContext(CharacterContext)
    const {totalCost, serializeCost, spendCurrency, current} = useContext(CurrencyContext)
    const {addNotice} = useContext(NoticeContext)
    const {items, hideCart} = useContext(InventoryContext)
    const [showCart, setShowCart] = useState(false)
    const [after, setAfter] = useState(current)

    async function settleCart(cart) {
        
        // here is where we will add the items to the character and reduce the money
        const _items = items.slice()
        cart.forEach((item) => {
            const inInventoryIndex = _items.findIndex(({type}) => type === item.type)
            if (inInventoryIndex > -1) {
                const inInventory = _items[inInventoryIndex]
                inInventory.qty = inInventory.qty || 1
                inInventory.qty += item.qty
                _items.splice(inInventoryIndex, 1, inInventory)
            } else {
                _items.unshift(item)
            }
        })
        spendCurrency(total).then((currency) => {
            updateCharacter(character, "inventory", {items: _items, ...currency})

            setCart([])
            setShowCart(false)
            addNotice("Purchase complete", `You have purchased ${cart.length} items for ${total}`, "success")
        }).catch((err) => {
            setShowCart(false)
            addNotice("Insufficient funds", err, "danger")
        })
    }

    useEffect(() => {
        const cost = totalCost(cart)
        let itemCount = cart.reduce((acc, item) => {
            return acc + (item.qty || 1)
        }, 0)
        setTotal(serializeCost(cost))
        setItemCount(itemCount)
    }, [cart])

    useEffect(() => {
        spendCurrency(total).then((currency) => {
            setAfter(currency)
        }).catch((err) => {
            addNotice("Insufficient funds", err, "danger")
            return Promise.resolve({})
        })
    }, [total])

    return <div style={{
        position: "fixed",
        zIndex: 1070,
        bottom: 100,
        right: 40
    }}>
        <Button size="lg" onClick={() => setShowCart(true)} className={hideCart ? "d-none" : "d-flex"}><i className="bi-cart"/> {itemCount}</Button>
        <Modal size="lg" show={showCart} onHide={() => setShowCart(false)}>
            <Card>
                <Card.Header>
                    <h3>Shopping Cart</h3>
                </Card.Header>
                <Card.Body>
                    <ListGroup>
                        {cart.map((item) => {
                            return <ListGroup.Item key={item.id}>
                                <Row>
                                    <Col xs={6}>{item.type}</Col>
                                    <Col xs={2}>{item.qty || 1}</Col>
                                    <Col xs={2}>{item.cost}</Col>
                                    <Col xs={2}><Button onClick={() => {
                                        const _cart = cart.slice()
                                        const index = _cart.findIndex(({id}) => id === item.id)
                                        const cartItem = cart[index]
                                        if (cartItem.qty > 1) {
                                            cartItem.qty -= 1
                                            _cart.splice(index, 1, cartItem)
                                            setCart([..._cart])
                                        } else {
                                            _cart.splice(index, 1)
                                            setCart([..._cart])
                                        }
                                    }}><i className="bi-dash"/></Button></Col>
                                </Row>
                            </ListGroup.Item>
                        })}
                        <ListGroup.Item>
                            <Row>
                                <Col xs={6}>Totals:</Col>
                                <Col xs={2}>{itemCount}</Col>
                                <Col xs={2}>{total}</Col>
                                <Col xs={2}></Col>
                            </Row>
                            <Row>
                                <Col xs={6}>Current:</Col>
                                <Col xs={2}></Col>
                                <Col xs={2}>{serializeCost(current)}</Col>
                                <Col xs={2}></Col>
                            </Row>
                            <Row>
                                <Col xs={6}>After:</Col>
                                <Col xs={2}></Col>
                                <Col xs={2}>{serializeCost(after)}</Col>
                                <Col xs={2}></Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                    <Button onClick={() => settleCart(cart, total)}>Pay</Button>
                </Card.Body>
            </Card>
        </Modal>
    </div>
}
