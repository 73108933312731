import React, {useContext} from "react"
import Navbar from "react-bootstrap/Navbar"
import UserContext from "services/UserContext"

export default function Brand() {
    const {user} = useContext(UserContext)
    const to = user?.token ? "/welcome" : "/"
    return <Navbar.Brand href={to} className={"d-flex brand flex-wrap justify-align-middle"} style={{
        backgroundImage: "url(/logo512.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        color: "#2c99ce",
        fontSize: "2em",
        paddingLeft: 70
    }}><span>Beyond</span></Navbar.Brand>
}