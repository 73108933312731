const {hostname} = window.location
const hostParts = hostname.replace("www.", "").split(".")
if (hostParts.length >= 3) {
    if (hostParts[0] === "dev" && hostParts.length === 3) {
        hostParts.splice(hostParts.indexOf("osrbeyond"), 0, "api")
    } else {
        // this contains a ruleset, which we can ignore in the api url
        hostParts.splice(hostParts.indexOf("osrbeyond") - 1, 1, "api")
    }
} else {
    hostParts.splice(hostParts.indexOf("osrbeyond"), 0, "api")
}
let API_HOST = `https://${hostParts.join(".")}`
let HOST = `https://${hostParts.join(".").replace("api.", "")}`
if (hostname.indexOf("local") > -1) {
    API_HOST = `http://${hostParts.join(".")}:4001`
    HOST = `http://${hostParts.join(".").replace("api.", "")}:3000`
}

const shortHosts = {
    "osrbeyond.local": "http://osrbeyond.local:3000",
    "localhost": "http://localhost:3000",
    "osrbeyond.com": "https://osrb.in",
    "staging.osrbeyond.com": "https://staging.osrb.in",
    "dev.osrbeyond.com": "https://dev.osrb.in",
}

const shortKeyPrefixes = ["staging", "dev", "localhost", "osrbeyond", "com", "local"]
const shortHostKey = hostname.split(".").reduce((memo, hostPart) => {
    if (shortKeyPrefixes.includes(hostPart)) {
        if (memo === "") {
            return hostPart
        }
        return `${memo}.${hostPart}`
    }
    return memo
}, "")

export const SHORT_HOST = shortHosts[shortHostKey]
export const CLIENT_HOST = HOST

export default API_HOST

