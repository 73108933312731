import React, { useEffect, useContext } from "react"
import defaultAvatar from "assets/avatar.jpg"
import defaultCover from "assets/campaign-cover3.jpg"
import Dropdown from "react-bootstrap/Dropdown"
import UserContext from "services/UserContext"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import DiceContext from "services/DiceContext"
import ClientContext from "services/ajax"

const sizes = {
    xs: {height: 20, width: 20},
    sm: {height: 30, width: 30},
    md: {height: 40, width: 40},
    lg: {height: 50, width: 50},
    xl: {height: 80, width: 80},
    xxl: {height: 100, width: 100}
}

export function CharacterControlContainer({character, user, children}) {
    return <Dropdown align="end" variant={"dark"}  id='dropdown-avatar'>
        <Dropdown.Toggle variant={"outline-dark"} className={"d-flex"}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu className={user.id === character.owner_id ? "" : "d-none"}>
            <Dropdown.Item href={`/character/${character.id}`}>View</Dropdown.Item>
            <Dropdown.Item href={`/characters/builder/${character.system}/${character.build}/${character.id}`}>Edit</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
}

export function UserControlContainer({children}) {
    const {user, setUser} = useContext(UserContext)
    const {sound, setSound,roll3d, setRoll3d} = useContext(DiceContext)
    const {updateUser, logoutUser} = useContext(ClientContext)

    useEffect(() => {
        if (sound !== user?.settings?.sound) {
            setSound(user?.settings?.sound)
        }
        if (roll3d !== user?.settings?.roll3d) {
            setRoll3d(user?.settings?.roll3d)
        }
    }, [user])

    useEffect(() => {
        const hasValue = (sound !== undefined || roll3d !== undefined)
        const hasChanged = (user?.settings?.sound !== sound || user?.settings?.roll3d !== roll3d)
        if (hasValue && user && hasChanged) {
            updateUser({settings: {sound, roll3d}}).then((user) => {
                setUser(user)
            })
        }
    }, [sound, roll3d])

    return <Dropdown align="end" variant={"dark"}  id='dropdown-avatar'>
        <Dropdown.Toggle variant={"black"} className={"d-flex align-items-center justify-content-center"}>
            {children}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{
            zIndex:1060
        }}>
            <Dropdown.Item href="/profile">Profile</Dropdown.Item>
            <Dropdown.Item hidden={!user?.roles?.includes("administrator")} href="/admin">Admin</Dropdown.Item>
            <Dropdown.Item onClick={(e) => {
                e.preventDefault()
                logoutUser().then(() => {
                    sessionStorage.removeItem("user")
                    setUser(null)
                })}} href="/user/logout">Logout</Dropdown.Item>
            <Container>
                <Form.Check type="switch" id="sound" label="Sound" checked={sound} onChange={() => setSound(!sound)}/>
                <Form.Check type="switch" id="roll3d" label="3D Dice" checked={roll3d} onChange={() => setRoll3d(!roll3d)}/>
            </Container>
        </Dropdown.Menu>
    </Dropdown>
}

export function CharacterAvatar({character, xs, sm, md, lg, xl, xxl, className}) {
    const size = sizes[xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : xxl ? "xxl" : "md"]

    return <div className={`d-inline-block text-light rounded-circle align-middle ${className}`} style={{
        ...size, 
        backgroundColor: "#666",
        backgroundImage: `url(${character?.settings?.avatar || defaultAvatar})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }}></div>
}

export function CampaignAvatar({campaign, xs, sm, md, lg, xl, xxl, className}) {
    const size = sizes[xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : xxl ? "xxl" : "md"]

    return <div className={`d-inline-block text-light rounded-circle align-middle ${className}`} style={{
        ...size, 
        backgroundColor: "#666",
        backgroundImage: `url(${campaign?.cover || defaultCover})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }}></div>
}

export function UserAvatar({user, xs, sm, md, lg, xl, xxl}) {
    let renderUser = user
    if (!renderUser) {
        renderUser = useContext(UserContext).user
    }
    const size = sizes[xs ? "xs" : sm ? "sm" : md ? "md" : lg ? "lg" : xl ? "xl" : xxl ? "xxl" : "md"]
    return <div className={"d-flex text-light  rounded-circle align-middle"} style={{
        ...size,
        backgroundColor: "#666",
        backgroundImage: `url(${renderUser?.avatar || defaultAvatar})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    }}></div>
}