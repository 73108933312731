import React, {useContext} from "react"
import { Container, Navbar, Row, Col, InputGroup, Form, Button, Nav } from "react-bootstrap"
import splash from "../assets/1.jpg"
import splashSm from "../assets/1-sm.jpg"
import characters from "../assets/characters2.jpg"
import campaigns from "../assets/campaign-cover2.jpg"
import whoweare from "../assets/whoweare3.jpg"
import charactersSm from "../assets/characters2-sm.jpg"
import campaignsSm from "../assets/campaign-cover2-sm.jpg"
import whoweareSm from "../assets/whoweare3-sm.jpg"
import ClientContext from "services/ajax"
import Brand from "components/Brand"
import {NavLink} from "react-router-dom"

import BlurredLoaderBackgroundImage from "components/BlurredLoaderBackgroundImage"

export default function Splash() {
    const { emailSignup } = useContext(ClientContext)
    const [email, setEmail] = React.useState("")
    const [isSignedUp, setIsSignedUp] = React.useState(localStorage.getItem("signedUp"))

    return <Container className={"p-0"} fluid>
        <Row>
            <Col className={"text-center"}>
                <Navbar bg="black" variant="dark" fixed="top" className="justify-content-between">
                    <Brand/>
                    <div className={"d-flex flex-row text-white"}>
                        <Nav.Link as={NavLink} className="pe-3" to="/register">Sign Up</Nav.Link> <Nav.Link as={NavLink} className="border-start border-white px-3" to="/login">Sign In</Nav.Link>
                    </div>
                </Navbar>
            </Col>
        </Row>
        <BlurredLoaderBackgroundImage Component={Container} fluid sm={splashSm} src={splash} style={{  
            height: "90vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Row className={"justify-content-center align-items-center"} style={{
                height: "100%",
            }}>
                <Col className={"text-center"}>
                    <div style={{
                        textShadow: "2px 2px 4px #000000"
                    }}>
                        <h1 className={"text-light brand"}>Welcome to the world of OSR Beyond</h1>
                        <p className={"text-light"}>Bringing high quality digital tools to your favorite OSR games.</p>
                    </div>
                    {!isSignedUp && <InputGroup className={"mx-auto"}>
                        <InputGroup.Text id="basic-addon1">Stay Informed</InputGroup.Text>
                        <Form.Control type="text" placeholder="Email" val={email} onChange={({target}) => setEmail(target.value)} aria-label="Search" aria-describedby="basic-addon1" />
                        <Button variant="primary" onClick={() => {
                            emailSignup(email).then(() => {
                                sessionStorage.setItem("signedUp", true)
                                setIsSignedUp(true)
                            })
                        }}>Sign Up</Button>
                    </InputGroup>}
                    {isSignedUp && <h3 className={"text-light"}>Thanks for signing up!</h3>}
                </Col>
            </Row>
        </BlurredLoaderBackgroundImage>
        <Container fluid className={"bg-black text-light h-auto"}>
            <Container>
                <Row className={"pt-5"}>
                    <Col className={"d-flex flex-column"}>
                        <BlurredLoaderBackgroundImage className={"d-block w-100 rounded-3"} Component={Container} sm={charactersSm} src={characters} position={"top"} style={{height: 160}}/>
                        <h2 className={"brand"}>Character Lockers</h2>
                        <p>We are designing character creation wizards and robust character lockers for a variety of OSR games. Our character creation process really highlights the rules-light vibes of the OSR!</p>
                    </Col>
                    <Col className={"d-flex flex-column"}>
                        <BlurredLoaderBackgroundImage className={"d-block w-100 rounded-3"} Component={Container} sm={campaignsSm} src={campaigns} position={"top"} style={{height: 160}}/>
                        <h2 className={"brand"}>Campaign Manager</h2>
                        <p>The convenience of having all the character sheets in one place, and a handy link to invite your new players in! Super convenient for GM&apos;s running multiple games with different systems.</p>
                    </Col>
                    <Col className={"d-flex flex-column"}>
                        <BlurredLoaderBackgroundImage className={"d-block w-100 rounded-3"} Component={Container} sm={whoweareSm} src={whoweare} style={{height: 160}}/>
                        <h2 className={"brand"}>Who We Are</h2>
                        <p>Quest Giver Games LTD. is a Canadian company that has been creating TTRPG products since 2019. We play OSR enthusiatically, ever since it was the only version.</p>
                    </Col>
                </Row>    
                <Row>
                    <Col>
                        <h1 className={"brand"}>What&apos;s Next?</h1>
                        <p>We are currently hard at work developing the Chararacter Locker and Campaign Manager for Lamentations of the Flame Princess, Old School Essentials, Troika!, and Blueholme. We expect to have our closed alpha test for these features in Summer 2024. Once we have cleared those milestones we expect to engage the community about what features we should invest in next.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h5>Wishlist Features</h5>
                        <ul>
                            <li>Homebrew collections and creation portal.</li>
                            <li>Game rules for each ruleset (publicly accessible).</li>
                            <li>Marketplace for digital versions of OSR content.</li>
                            <li>Encounter Builder.</li>
                            <li>Campaign Logger and Note Taking Utilities.</li>
                            <li>Mapping Utilities.</li>
                        </ul>
                    </Col>
                    <Col>
                        <h5>Wishlist Rulesets</h5>
                        <ul>
                            <li>OSRIC</li>
                            <li>Shadowdark</li>
                            <li>Knave</li>
                            <li>Knave 2e</li>
                            <li>Mausritter</li>
                            <li>Into the Odd</li>
                            <li>Dungeon Crawl Classics</li>
                            <li>Basic Fantasy</li>
                            <li>Worlds Without Number</li>
                            <li>Tunnels & Trolls</li>
                            <li>Blueholme</li>
                            <li>Crowns</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    </Container>
}
