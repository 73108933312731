import React, {useEffect, useState, useContext} from "react"
import { Container, InputGroup, Form, Button } from "react-bootstrap"
import UserContext from "services/UserContext"
import ClientContext from "services/ajax"
import NoticeContext from "services/NoticeContext"

export default function SubmitRuleset() {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [url, setUrl] = useState("")
    const [srd, setSrd] = useState("")
    const [message, setMessage] = useState("")
    const {user} = useContext(UserContext)
    const {sendFeedback} = useContext(ClientContext)
    const {addNotice} = useContext(NoticeContext)

    useEffect(() => {
        setMessage(`Ruleset Request
        ----------------
        Name: ${name}
        Description: ${description}
        URL: ${url}
        SRD: ${srd}`)
    }, [name, description, url, srd])

    return <Container className="mt-4">
        <h1 className={"brand"}>Request a Ruleset</h1>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <InputGroup className="mt-3">
            <InputGroup.Text className="w-25">Ruleset Name</InputGroup.Text>
            <Form.Control value={name} onChange={({target}) => {
                setName(target.value)
            }} type='text'></Form.Control>
        </InputGroup>
        <InputGroup className="mt-3">
            <InputGroup.Text className="w-25">Description</InputGroup.Text>
            <Form.Control value={description} onChange={({target}) => {
                setDescription(target.value)
            }} type='text' as="textarea"></Form.Control>
        </InputGroup>
        <InputGroup className="mt-3">
            <InputGroup.Text className="w-25">URL</InputGroup.Text>
            <Form.Control value={url} onChange={({target}) => {
                setUrl(target.value)
            }} type='url'></Form.Control>
        </InputGroup>
        <InputGroup className="mt-3">
            <InputGroup.Text className="w-25">SRD</InputGroup.Text>
            <Form.Control value={srd} onChange={({target}) => {
                setSrd(target.value)
            }} type='url'></Form.Control>
        </InputGroup>
        <Button className="mt-3" onClick={() => {
            if (name, description, url, srd) {
                sendFeedback(user.email, message).then(() => {
                    addNotice("Request Sent", "Thank you for sending us this recommendation. We will get right on it!","success")
                    setName("")
                    setDescription("")
                    setUrl("")
                    setSrd("")
                })
            }
        }}>Send Request</Button>
    </Container>
}