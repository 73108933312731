export default function convertCharacter(character, settings) {
    return {
        type: "Character",
        if: character.id,
        avatar: character.settings.avatar,
        name: character.settings.name,
        ac: 12,// calculated ac
        hp: character.instance.hp.current,
        "max-hp": character.instance.hp.max,
        classes: {
            [character.class.name]: character.instance.level
        },
        level: character.instance.level,
        settings: settings,
        url: window.location.href,
    }
}
