import React, {createContext, useContext, useEffect, useMemo, useState} from "react"
import CharacterContext from "services/CharacterContext"
import CurrencyContext from "./CurrencyContext"
import RulesetContext from "services/RulesetContext"
import {v4} from "uuid"
import chaching from "assets/chaching.mp3"
import ShoppingCart from "components/ShoppingCart"

const InventoryContext = createContext(null)

export function InventoryContextProvider(props) {
    const {children} = props
    const {character, addItem, deleteItem, updateItem} = useContext(CharacterContext)
    const {sources} = useContext(RulesetContext)
    const [items, setItems] = useState(character?.inventory?.items || [])
    const [categories, setCategories] = useState([])
    const [market, setMarket] = useState(null)
    const [marketType, setMarketType] = useState("city")
    const [cart, setCart] = useState([])
    const [hideCart, setHideCart] = useState(false)
    useEffect(() => {
        if (character?.inventory?.items) {
            setItems(character.inventory.items)
        }
        if (sources) {
            const _categories = new Set()
            const _market = sources.items.reduce((acc, item) => {
                _categories.add(item.category)
                acc[item.category] = acc[item.category] || []
                acc[item.category].push(item)
                return acc
            }, {})
            setMarket(_market)
            setCategories(_categories)
        }
    }, [sources, character])

    useEffect(() => {
        if (cart.length > 0) {
            setHideCart(false)
        } else {
            setHideCart(true)
        }
    }, [cart])

    function chaChingSound() {
        const snd1  = new Audio()
        const src1  = document.createElement("source")
        src1.type = "audio/mpeg"
        src1.src  = chaching
        snd1.appendChild(src1)
        snd1.play()
        setTimeout(() => {
            src1.remove()
        }, 1000)
    }

    const itemsByContainer = useMemo(() => {
        const vehicles = items.filter(({category}) => ["Vehicles"].includes(category))
        const animals = items.filter(({category}) => ["Animals"].includes(category))
        const containers = items.filter(({category}) => ["Containers"].includes(category))
        const rest = items.filter(({category}) => !["Containers", "Vehicles", "Animals"].includes(category))
        const _items = [...vehicles, ...animals, ...containers, ...rest]
        return _items.reduce((acc, item) => {
            if (item.category === "Containers") {
                acc[`${item.id}`] = acc[item.id] || {contents: {}, type: item.type}
            }
            if (item.category === "Vehicles") {
                acc[`${item.id}`] = acc[item.id] || {contents: {}, type: item.type}
            }
            if (item.category === "Animals") {
                acc[`${item.id}`] = acc[item.id] || {contents: {}, type: item.type}
            }
            if (item.container) {
                const container = _items.find(({id}) => id === item.container)
                if (!container) {
                    item.container = null
                    return acc
                }
                if (!acc[item.container] && container) {
                    acc[item.container] = container
                    container.contents = {}
                }
                acc[item.container].contents[item.id] = item
            } else {
                acc.inventory.contents[item.id] = item
            }
            return acc
        }, {inventory: {type: "Inventory", contents: {}}})
    }, [items])

    async function purchaseItem(item, isFree = false) {
        const _cart = cart.slice()
        const inCartIndex = _cart.findIndex(({type, cost}) => {
            const priceMatch = cost === item.cost[marketType]
            const freeMatch = cost === "0sp"
            const typeMatch = type === item.type
            return typeMatch && ((!isFree && priceMatch) || (isFree && freeMatch))
        })
        const inCart = inCartIndex > -1
        if (inCart) {
            const itemCopy = {..._cart[inCartIndex]}
            itemCopy.qty = itemCopy.qty || 1
            itemCopy.qty += 1
            _cart.splice(inCartIndex, 1, itemCopy)
        } else {
            _cart.unshift({...item, qty: 1, cost: isFree ? "0sp" : item.cost[marketType], id: v4()})
        }
        setCart(_cart)
    }

    function _addItem(item) {
        if (!["Containers", "Vehicles", "Animals"].includes(item.category) && item.id) {
            updateItem(character, item.id, {qty: (item.qty || 1) + 1}).then((item) => {
                items.splice(items.findIndex(({id}) => id === item.id), 1, item)
                setItems([...items])
            })
        } else {
            return addItem(character, {...item, id: v4(), qty: 1}).then((item) => {
                items.unshift(item)
                setItems([...items])
            })
        }
    }

    function removeItem(item) {
        if (item.qty > 1) {
            return updateItem(character, item.id, {qty: item.qty - 1}).then((item) => {
                items.splice(items.findIndex(({id}) => id === item.id), 1, item)
                setItems([...items])
            })
        } else {
            return deleteItem(character, item.id).then(() => {
                items.splice(items.findIndex(({id}) => id === item.id), 1)
                setItems([...items])
            })
        }
    }

    function stowItem(item, container) {
        return updateItem(character, item.id, {container: container?.id || null}).then((item) => {
            items.splice(items.findIndex(({id}) => id === item.id), 1, item)
            setItems([...items])
        })
    }

    function equipItem(item, equipped) {
        return updateItem(character, item.id, {equipped}).then((item) => {
            items.splice(items.findIndex(({id}) => id === item.id), 1, item)
            setItems([...items])
        })
    }

    function saveItem(item) {
        return updateItem(character, item.id, item).then((item) => {
            items.splice(items.findIndex(({id}) => id === item.id), 1, item)
            setItems([...items])
        })
    }

    const value = useMemo(() => {
        return {
            categories,
            items,
            chaChingSound,
            addItem: _addItem,
            purchaseItem,
            removeItem,
            equipItem,
            stowItem,
            setItems,
            saveItem,
            itemsByContainer,
            market,
            marketType,
            setMarketType,
            hideCart,
            setHideCart               
        }
    }, [items, market, marketType, cart])

    return <InventoryContext.Provider value={value}>
        <ShoppingCart cart={cart} setCart={setCart} CurrencyContext={CurrencyContext} InventoryContext={InventoryContext}/>
        {children}
    </InventoryContext.Provider>
}

export default InventoryContext