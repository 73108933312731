import React, { useState, useEffect, useLayoutEffect, useRef, forwardRef } from "react"
import { Accordion, Button, Container } from "react-bootstrap"

export default function SelectList({items, selectedItem, ItemTemplate, onSelect}) {
    const selectedElement = useRef(null)
    const [_selectedItem, setSelectedItem] = useState(selectedItem)

    const RefTemplate = forwardRef(ItemTemplate)

    useEffect(() => {
        if (selectedItem) {
            const selectedIndex = (items || []).findIndex((b) => b.name === selectedItem.name)
            if (selectedIndex > -1) {
                items.splice(selectedIndex, 1)
                items.unshift(selectedItem)
            }
        }
        setSelectedItem(selectedItem || [items[0]])
    }, [items])

    useLayoutEffect(() => {
        setTimeout(() => {
            selectedElement?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start"
            })  
        }, 500)
    }, [selectedElement.current, _selectedItem])

    return <Container className={"overflow-y-hidden"}>
        <Button onClick={() => {
            const r = parseInt((Math.random() * items.length) + 1, 10)
            setSelectedItem(items[r])
        }}>Select Random</Button>
        <Accordion activeKey={_selectedItem?.name} defaultActiveKey={"none"} flush onSelect={(i) => {
            setSelectedItem(items.find(({name}) => name === i))
        }}>
            {items.map((item, i) => {
                return <RefTemplate key={`background-${i}`} item={item} selectedItem={_selectedItem} ref={selectedElement} onSelect={onSelect}/>
            })}
        </Accordion>
    </Container>
}