import React, { useContext, useMemo } from "react"
import {useState, useEffect} from "react"
import { Col, Row, Container, Tab, ListGroup, Button, Card, Nav, Form, InputGroup, FloatingLabel } from "react-bootstrap"
import {
    useParams,
    NavLink
} from "react-router-dom"
import ClientContext from "services/ajax"
import {format} from "date-fns"
import { getImagePath } from "services/util"
import API_HOST from "services/api"

function Cohort({cohort, whitelist, inviteToWhitelist, refreshWhitelist}) {
    const [singleInvite, setSingleInvite] = useState("")
    return <ListGroup>
        <h4>{cohort}</h4>
        <InputGroup>
            <FloatingLabel label="email">
                <Form.Control type="text" value={singleInvite} onChange={({target}) => setSingleInvite(target.value)}></Form.Control>
            </FloatingLabel>
            <Button onClick={() => {
                inviteToWhitelist(cohort, singleInvite).then(refreshWhitelist)
            }}>Add</Button>
        </InputGroup>
        <ListGroup.Item>
            <Row>
                <Col>Email</Col>
            </Row>
        </ListGroup.Item>
        {whitelist.filter(({cohort: c}) => c === cohort).map(({email}) => {
            return <ListGroup.Item key={`${cohort}-${email}`}>
                <Row>
                    <Col>{email}</Col>
                </Row>
            </ListGroup.Item>
        })}
    </ListGroup>

}
function UserManager() {
    const {admin: {getMailchimpSubscribers, getUsers, deleteUser, getWhitelist, inviteToWhitelist}} = useContext(ClientContext)    
    const [subscribers, setSubscribers] = useState([])
    const [whitelist, setWhitelist] = useState([])
    const [subCount, setSubCount] = useState(0)
    const [users, setUsers] = useState([])
    const [subSort, setSubSort] = useState("email_address")
    const [dir, setDir] = useState(1)
    const [cohort, setCohort] = useState("")
    const [invites, setInvites] = useState([])

    const refreshWhitelist = useMemo(() => {
        getWhitelist().then((w) => {
            setWhitelist(w)
        })        
    }, [getWhitelist, setWhitelist])

    useEffect(() => {
        getUsers().then((u) => {
            setUsers(u)
        })
        getWhitelist().then((w) => {
            setWhitelist(w)
        })
    }, [])

    const icons = {
        subscribed: <i className={"bi-check text-success"}/>,
        pending: <i className={"bi-hourglass-split text-warning"}/>,
        unsubscribed: <i className={"bi-x text-danger"}/>,
    }

    return <Container>
        <h1>User Manager</h1>
        <Row>
            <Col>
                <h3>Mailchimp Subscribers ({subCount})</h3>
                <Button onClick={() => {
                    getWhitelist().then((w) => {
                        setWhitelist(w)
                    })
                    getMailchimpSubscribers().then((s) => {
                        setSubscribers(s.members)
                        setSubCount(s.total_items)
                    })
                }}>Load Subscribers</Button>
                <ListGroup>
                    <ListGroup.Item>
                        <Row>
                            <Col xs={6} onClick={() => {
                                setSubSort("email_address")
                                setDir(dir * -1)
                            }}>Email</Col>
                            <Col xs={2} onClick={() => {
                                setSubSort("status")
                                setDir(dir * -1)
                            }}>Status</Col>
                            <Col xs={2} onClick={() => {
                                setSubSort("timestamp_signup")
                                setDir(dir * -1)
                            }}>Date</Col>
                            <Col xs={2}>Actions</Col>
                        </Row>
                    </ListGroup.Item>
                    {subscribers.map((s) => {
                        return {
                            ...s,
                            email_address: s.email_address.toLowerCase()
                        }
                    }).sort((a, b) => {
                        if (a[subSort] < b[subSort]) {
                            return -1 * dir
                        } else if (a[subSort] > b[subSort]) {
                            return 1 * dir
                        } else {
                            return 0
                        }
                    }).map((s) => {
                        const inInvites = invites.includes(s.email_address)
                        const isSubscribed = s.status === "subscribed"
                        const onWhitelist = whitelist.find(({email}) => email ===  s.email_address)
                        const signupDate = Date.parse(s.timestamp_signup)
                        return <ListGroup.Item className={users.find(({email}) => email === s.email_address) ? "bg-success" : ""} key={s.id}>
                            <Row>
                                <Col xs={6}>{s.email_address}</Col>
                                <Col xs={2}>{icons[s.status] }</Col>
                                <Col xs={2}>{isNaN(signupDate) ? "Unknown" : format(Date.parse(s.timestamp_signup), "MM-dd-yy")}</Col>
                                <Col xs={2}><Button disabled={onWhitelist || inInvites || !isSubscribed} onClick={() => {
                                    setInvites([...invites, s.email_address])
                                }}>Invite</Button></Col>
                            </Row>
                        </ListGroup.Item>
                    })}
                </ListGroup>
            </Col>
            <Col>
                <h3>Registered Users</h3>
                <ListGroup>
                    <ListGroup.Item>
                        <Row>
                            <Col xs={6}>Email</Col>
                            <Col xs={2}># Records</Col>
                            <Col xs={4}>Actions</Col>
                        </Row>
                    </ListGroup.Item>
                    {users.map((u) => <ListGroup.Item key={u.public}>
                        <Row>
                            <Col xs={6}>{u.email}</Col>
                            <Col xs={2}>{u.characterCount || 0} <i className="bi-person-circle"/> {u.campaignCount || 0} <i className="bi bi-book-fill"/></Col>
                            <Col xs={4}>
                                <Button><i className="bi-pen"/></Button>
                                <Button variant={"danger"} onClick={() => {
                                    if (confirm(`Are you sure you want to delete ${u.email}?`)) {
                                        deleteUser(u.public).then(() => window.location.reload())
                                    }
                                }}><i className="bi-trash"/></Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>)}
                </ListGroup>
                <h3>White List</h3>
                <h5>Ready to Invite {invites.length}</h5>
                {invites.length > 0 && <>
                    <InputGroup>
                        <Form.Control type="text" value={cohort} onChange={({target}) => setCohort(target.value)}></Form.Control>
                        <Button onClick={() => {
                            inviteToWhitelist(cohort, invites).then(refreshWhitelist)
                        }}>Add</Button>
                    </InputGroup>
                </>}
                {whitelist.reduce((acc, {cohort}) => {
                    if (acc.includes(cohort)) return acc
                    acc.push(cohort)
                    return acc
                }, []).map((cohort) => <Cohort key={cohort} cohort={cohort} whitelist={whitelist} inviteToWhitelist={inviteToWhitelist} refreshWhitelist={refreshWhitelist}/>)}
            </Col>
        </Row>
    </Container>
}

function DatabaseManager() {
    const [tables, setTables] = useState([])
    const [migrations, setMigrations] = useState([])
    const {admin} = useContext(ClientContext)
    useEffect(() => {
        admin.getTables().then(tables => setTables(tables))
        admin.getMigrations().then(migrations => setMigrations(migrations))
    }, [])

    return <>
        <Button className='mt-3' onClick={admin.runSeed}>Run Seed</Button>
        <ListGroup className="mt-3">
            {Object.entries(tables).map(([table, count], i) => <ListGroup.Item key={`table-${i}`}>{table} [{count}]</ListGroup.Item>)}
        </ListGroup>
        <ListGroup className="mt-3">
            <ListGroup.Item><Button onClick={() => {
                admin.migrateUp().then(() => window.location.reload())
            }}><i className={"bi-arrow-up"}/></Button><Button onClick={() => {
                admin.migrateDown().then(() => window.location.reload())
            }}><i className={"bi-arrow-down"}/></Button></ListGroup.Item>
            {migrations.map(({name, hasRun}, i) => <ListGroup.Item key={`table-${i}`}>{name} {hasRun ? <i className={"bi-check text-success"}/> : null}</ListGroup.Item>)}
        </ListGroup>
    </>
}

function ImageManager() {
    const [buckets, setBuckets] = useState([])
    const [images, setImages] = useState(null)
    const {admin} = useContext(ClientContext)

    useEffect(() => {
        if (images === null) {
            admin.getBuckets().then(buckets => {
                if (!Array.isArray(buckets)) {
                    setBuckets([])
                } else {
                    setBuckets(buckets || [])
                }
            })
            admin.getImages().then(images => {
                if (!Array.isArray(images)) {
                    setImages([])
                } else {
                    setImages(images || [])
                }
            })
        }
    }, [images])

    if (images === null) return <Container>Loading...</Container>

    return <Container>
        <h1>Image Manager</h1>
        <Button onClick={() => {admin.createBucket().then(buckets => setBuckets(buckets))}}>Create Default Bucket</Button>
        <ListGroup>
            {buckets.map((bucket) => {
                return <ListGroup.Item key={bucket.Name}>{bucket.Name}</ListGroup.Item>
            })}
        </ListGroup>
        <Container className="d-flex flex-row flex-wrap">
            {images.map((img) => {
                return <Card key={img.id} className="w-25">
                    <Card.Img src={img.location || `${API_HOST}${getImagePath(img)}`}/>
                    <Card.Body>
                        <Card.Title>{img.original_name}</Card.Title>
                        <Card.Text>
                            {!img.location && img.onDisk && <Button onClick={() => {admin.migrateImageToS3(img).then(() => {
                                setImages([])
                            })}} variant="primary">Migrate to S3</Button>}
                            {img.location && !img.onDisk && <>Already Migrated to S3</>}
                        </Card.Text>
                    </Card.Body>

                </Card>
            })}
        </Container>
    </Container>
}

export default function Admin() {
    const {subpage} = useParams()
    return <Container>
        <Tab.Container activeKey={subpage || "users"}>
            <Nav variant="tabs">
                <Nav.Item>
                    <Nav.Link as={NavLink} eventKey="users" to="/admin/users">Users</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} eventKey="databases" to="/admin/databases">Database</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={NavLink} eventKey="images" to="/admin/images">Images</Nav.Link>
                </Nav.Item>
            </Nav>
            <Tab.Content>
                <Tab.Pane eventKey="users">
                    <UserManager/>
                </Tab.Pane>
                <Tab.Pane eventKey="databases">
                    <DatabaseManager/>
                </Tab.Pane>
                <Tab.Pane eventKey="images">
                    <ImageManager/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    </Container>
}