import React, {useContext, lazy, Suspense, useMemo} from "react"
import { Container, Row, Col } from "react-bootstrap"
import RulesetContext from "services/RulesetContext"
import {useParams, Navigate, Link} from "react-router-dom"

export function RulesInProgress() {
    const {ruleset} = useContext(RulesetContext)
    return <Container>
        <h1>{ruleset.name}</h1>
        <p>{ruleset.license}</p>
        <h2>If the rulebook doesn&apos;t load here, we are likely still in the process of digitizing it for consumption here. You may find the source material <Link to={ruleset.url}>Here</Link></h2>
    </Container>
}

export default function Rules() {
    const {ruleset} = useContext(RulesetContext)
    const {chapter} = useParams()

    const Contents = lazy(() => import(`../components/${ruleset.id}/rules/Contents`).catch(() => null))
    const Content = useMemo(() => {
        return lazy(() => import(`../components/${ruleset.id}/rules/Content`).catch(() => null))
    }, [chapter])    

    if (!chapter) {
        return <Navigate to="/rules/1" replace={true}/>
    }

    return <Container className="mt-4">
        <Suspense fallback={<RulesInProgress/>}>
            <Row>
                <Col xs={3}>
                    <Contents/>
                </Col>
                <Col xs={9}>
                    <Content chapter={chapter}/>
                </Col>
            </Row>
        </Suspense>
    </Container>
}