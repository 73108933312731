import React,{ useEffect, useContext } from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {UserAvatar, UserControlContainer} from "components/Avatar"
import RuleSetSelector from "components/RuleSetSelector"
import UserContext from "services/UserContext"
import Brand from "./Brand"
import {NavLink} from "react-router-dom"
import { SimpleDiceRoller } from "./DiceRoller"
import NoticeContext from "services/NoticeContext"
import DiceContext from "services/DiceContext"

export default function Header() {
    const {user} = useContext(UserContext)
    const {requestDice} = useContext(DiceContext)
    const {addNotice} = useContext(NoticeContext)

    useEffect(() => {
        requestDice()
    }, [])

    return <Container fluid className={"p-0 bg-black"}>
        <Row className="border-bottom border-primary border-3 mx-0">
            <Col className={"text-center"}>
                <Container className={"mx-auto"}>
                    <Navbar bg="black" variant="dark" style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundBlendMode: "hard-light"
                    }} expand="lg">
                        <Container>
                            <Row>
                                <Col xs={12} md={2} className="d-flex align-items-center justify-content-between">
                                    <Brand/>
                                    <Navbar.Toggle aria-controls="users-navbar" className="float-end"/>
                                </Col>
                            </Row>
                        </Container>

                        <Navbar.Collapse className={"justify-content-end"} id="basic-navbar-nav">
                            <Nav>
                                <UserControlContainer user={user}><UserAvatar sm user={user}/>&nbsp;<span className={"text-white"}>{user?.name}</span></UserControlContainer>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </Col>
        </Row>
        <Container fluid className={"w-100 bg-dark"}>
            <Container className={"mx-auto"}>
                <Row>
                    <Col>
                        <Navbar bg="dark" variant="dark" expand="lg" className="position-relative">
                            <div className="position-absolute" style={{
                                right: -10,
                                top: -60
                            }}>
                                <Navbar.Toggle aria-controls='users-navbar'/>
                            </div>
                            <Navbar.Collapse id='users-navbar' className='mx-auto text-light justify-content-between'>
                                <Nav className="gap-1">
                                    <Nav.Link as={NavLink} to="/characters">Characters</Nav.Link>
                                    <Nav.Link as={NavLink} to="/campaign">Campaigns</Nav.Link>
                                    <Nav.Link as={NavLink} to="/rules">Game Rules</Nav.Link>
                                    <Nav.Link as={NavLink} to="/feedback">Feedback</Nav.Link>
                                    <Nav.Link as={NavLink} to="/subscribe" disabled>Subscribe</Nav.Link>
                                    <div className="d-flex flex-row flex-wrap">
                                        <SimpleDiceRoller d="1d4" onResult={([d4]) => addNotice("Roll Result", `d4 [${d4.value}]`)}><i className="df-d4-4"/></SimpleDiceRoller>
                                        <SimpleDiceRoller d="1d6" onResult={([d6]) => addNotice("Roll Result", `d6 [${d6.value}]`)}><i className="df-d6-6"/></SimpleDiceRoller>
                                        <SimpleDiceRoller d="1d8" onResult={([d8]) => addNotice("Roll Result", `d8 [${d8.value}]`)}><i className="df-d8-8"/></SimpleDiceRoller>
                                        <SimpleDiceRoller d="1d10" onResult={([d10]) => addNotice("Roll Result", `d10 [${d10.value}]`)}><i className="df-d10-10"/></SimpleDiceRoller>
                                        <SimpleDiceRoller d="1d12" onResult={([d12]) => addNotice("Roll Result", `d12 [${d12.value}]`)}><i className="df-d12-12"/></SimpleDiceRoller>
                                        <SimpleDiceRoller d="1d20" onResult={([d20]) => addNotice("Roll Result", `d20 [${d20.value}]`)}><i className="df-d20-20"/></SimpleDiceRoller>
                                        <SimpleDiceRoller d="1d100" onResult={([d100]) => {
                                            addNotice("Roll Result", `d100 [${(d100.value) || 100}]`)
                                        }}><i className="df-d10-10"/><i className="df-d10-10"/></SimpleDiceRoller>
                                    </div>
                                </Nav>
                                <RuleSetSelector/>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </Container>
    </Container>
}