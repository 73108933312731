import React, {useState, useContext, useEffect} from "react"
import {useParams} from "react-router-dom"
import { Container, Form } from "react-bootstrap"
import ClientContext from "services/ajax"
import { CoverSelector } from "components/AvatarSelector"
import ReactQuill from "react-quill"

export default function CampaignEditor() {
    const {slug} = useParams()
    const [campaign, setCampaign] = useState(null)
    const [name, setName] = useState(campaign?.name)
    const [cover, setCover] = useState(campaign?.cover)
    const [description, setDescription] = useState(campaign?.description)
    const {getCampaign, updateCampaign} = useContext(ClientContext)
    const [willSave, setWillSave] = useState(false)

    useEffect(() => {
        getCampaign({slug}).then(setCampaign)
    }, [])

    useEffect(() => {
        setName(campaign?.name)
        setCover(campaign?.cover)
        setDescription(campaign?.description)
    }, [campaign])

    useEffect(() => {
        if(willSave) {
            clearTimeout(willSave)
        }
        setWillSave(setTimeout(() => {
            if (campaign?.id) {
                updateCampaign(campaign, {name, cover, description}).then(() => {
                    getCampaign({slug}).then(setCampaign)
                })
            }
        }, 1000))
    }, [name, cover, description])

    return <Container>
        <h1 className={"brand"}>Edit Campaign</h1>
        <hr className={"border-bottom border-primary border-4 opacity-100"}></hr>
        <CoverSelector id={campaign?.id} selected={cover} onChange={(cover) => {
            setCover(cover)
        }}/>
        <Form.Label as={"h3"} className={"brand"}>Name:</Form.Label>
        <Form.Control type="text" value={name} onChange={({target}) => {
            setName(target.value)
        }}></Form.Control>
        <Form.Label as={"h3"} className={"brand"}>Description</Form.Label>
        <ReactQuill theme="snow" value={description} onChange={setDescription}/>
    </Container>
}