import React, {useContext, useState, useEffect} from "react"
import { Accordion, Container, Button, ListGroup, Row, Col, Modal } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"
import NoticeContext from "services/NoticeContext"
import InventoryContext from "./Inventory/InventoryContext"
import RulesetContext from "services/RulesetContext"
import ReactQuill from "react-quill"
import DOMPurify from "dompurify"
import {v4} from "uuid"

export function SpellTemplate({selectLabel, canSelect, spell, onSelect, allowReverse, allowRemove, removeLabel, onRemove}) {
    const hasReverse = spell.name.slice(-1)[0] === "*"
    const spellDescription = DOMPurify.sanitize(spell.description)
    return <Accordion.Item eventKey={spell.name}>
        <Accordion.Header>
            <h6 className="brand">{spell.name}</h6> 
        </Accordion.Header>
        <Accordion.Body>
            <div className={"d-flex float-end gap-2"}>
                <Button disabled={!canSelect} onClick={() => {
                    const toPrepare = {...spell, reverse: false, id: v4()}
                    onSelect(toPrepare)
                }}>{selectLabel}</Button>
                {hasReverse && allowReverse && <Button disabled={!canSelect} className={"float-end"} onClick={() => {
                    const toPrepare = {...spell, name: spell.reversedName, id: v4()}
                    onSelect(toPrepare)
                }}>Reverse</Button>}
                {allowRemove && <Button className={"float-end"} onClick={() => {onRemove(spell)}}>{removeLabel}</Button>}
            </div>

            <p>{spell.type} Level {spell.level}</p>
            <p>Duration: {spell.duration}</p>
            <p>Range: {spell.range}</p>
            <p dangerouslySetInnerHTML={{__html: spellDescription}}></p>
        </Accordion.Body>
    </Accordion.Item>
}

// const addDiceDescriptions = (onResult, description) => {
//     const diceExp = /(\d+)?d(\d+)([+-]\d+)?/ig
//     const matches = description.matchAll(diceExp)
//     let match = matches.next()
//     const output = []
//     let ptr = 0
//     while(!match.done) {
//         output.push(description.slice(ptr, match.value.index))
//         output.push(<SimpleDiceRoller size="sm" onResult={onResult} label={match.value[0]} d={match.value[0]} />)
//         ptr = match.value.index + match.value[0].length
//         match = matches.next()
//     }
//     output.push(description.slice(ptr))
//     return output
// }

export function SpellsActionPanel({character, updateCharacter}) {
    const [show, setShow] = useState(false)
    const [showSpell, setShowSpell] = useState(false)
    return <Container className="mt-3">
        <Button onClick={() => setShow(true)}>Manage Spells</Button>
        <ListGroup>
            <ListGroup.Item>
                <Row>
                    <Col xs={1}><h6 className="brand">Level</h6></Col>
                    <Col xs={9}><h6 className="brand">Spells</h6></Col>
                    <Col xs={2}><h6 className="brand">Action</h6></Col>
                </Row>
            </ListGroup.Item>
            {(character.skills.spells || []).map((spell) => {
                return <ListGroup.Item key={spell.id}>
                    <Row>
                        <Col xs={1}>{spell.level}</Col>
                        <Col xs={9}>{spell.name}</Col>
                        <Col xs={1}><Button variant="warning" onClick={() => {
                            setShowSpell(spell)
                        }}>View</Button></Col>
                        <Col xs={1}><Button variant="danger" onClick={() => {
                            const spells = character.skills.spells.filter((s) => s.id !== spell.id)
                            updateCharacter(character, "skills", {spells})
                        }}>Cast</Button></Col>
                    </Row>
                </ListGroup.Item>
            })}
        </ListGroup>
        <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Spell Book</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CharacterSpells />
            </Modal.Body>
        </Modal>
        {showSpell && <Modal size="xl" show={showSpell} onHide={() => setShowSpell(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{showSpell?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(showSpell?.description || "")}}></p>
            </Modal.Body>
        </Modal>}
    </Container>
}

export function CreateSpell({spellBook, showCreateSpell, setShowCreateSpell}) {
    const {character} = useContext(CharacterContext)
    const [name, setName] = useState("")
    const [level, setLevel] = useState(1)
    const [type, setType] = useState(character.class.name === "Cleric" ? "Cleric" : "Magic-User")
    const [duration, setDuration] = useState("")
    const [range, setRange] = useState("")
    const [description, setDescription] = useState("")
    const {saveItem} = useContext(InventoryContext)
    const {addNotice} = useContext(NoticeContext)

    const onSave = () => {
        if (name && level && type && duration && range && description) {
            saveItem({...spellBook, spells: [...(spellBook?.spells || []), {name, level, type, duration, range, description, id: v4()}]})
            addNotice("Success", "Spell created successfully.", "success")
            setShowCreateSpell(false)
        } else {
            addNotice("Error", "All fields are required.", "danger")
        }
    }

    return <Modal size="lg" show={showCreateSpell} onHide={() => setShowCreateSpell(false)}>
        <Modal.Header closeButton>
            <Modal.Title>Create Custom Spell</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                <p>Fill out the form below to create a custom spell.</p>
                <Row>
                    <Col xs={6}>
                        <label>Name</label>
                        <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                    </Col>
                    <Col xs={3}>
                        <label>Level</label>
                        <input type="number" className="form-control" value={level} onChange={(e) => setLevel(e.target.value)} />
                    </Col>
                    <Col xs={3}>
                        <label>Type</label>
                        <select className="form-control" disabled value={type} onChange={(e) => setType(e.target.value)}>
                            <option value="Magic-User">Magic-User</option>
                            <option value="Cleric">Cleric</option>
                        </select>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <label>Duration</label>
                        <input type="text" className="form-control" value={duration} onChange={(e) => setDuration(e.target.value)} />
                    </Col>
                    <Col xs={6}>
                        <label>Range</label>
                        <input type="text" className="form-control" value={range} onChange={(e) => setRange(e.target.value)} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label>Description</label>
                        <ReactQuill value={description} onChange={setDescription} />
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setShowCreateSpell(false)}>Cancel</Button>
            <Button onClick={() => onSave()}>Save</Button>
        </Modal.Footer>
    </Modal>
}

export function SpellBook({spellBook, spellLevel, spellType, canPrepare, onPrepare, spells}) {
    const {character} = useContext(CharacterContext)
    const {purchaseItem, saveItem, chaChingSound, market, setHideCart} = useContext(InventoryContext)
    const [showCreateSpell, setShowCreateSpell] = useState(false)
    
    useEffect(() => {
        if (!spellBook) {
            setHideCart(true)
        } else {
            setHideCart(false)
        }
    }, [])

    const onUnscribe = (spell) => {
        if (spellBook) {
            saveItem({...spellBook, spells: (spellBook?.spells || []).filter(({id}) => id !== spell.id)})
        }
    }

    function buyNewSpellbook() {
        if (character?.id && spells?.length > 0 && market) {
            if (!spellBook) {
                const newSpellBook = {...(market["Miscellaneous Equipment"] || []).find(({type}) => type === "Book, Spell (Blank)"), spells: []}
                const readMagicSpell = spells.find(({name}) => name === "Read Magic")
                newSpellBook.spells.push({...readMagicSpell, id: v4()})
                chaChingSound()
                purchaseItem(newSpellBook)
            }
        }
    }

    return <Container>
        <div className={"d-flex float-end gap-2"}>
            <Button onClick={() => {
                setShowCreateSpell(true)
            }}>Create Custom Spell</Button>
        </div>
        <h4 className="brand">Spell Book</h4>
        <p>
            You may prepare any spells you have scribed in spell book.                                
        </p>
        {spellBook ? 
            <Accordion>

                {(spellBook?.spells || []).filter(({level, type}) => level == spellLevel && type === spellType).map((s) => {
                    return <SpellTemplate selectLabel={"Prepare"} canSelect={canPrepare} key={s.name} allowReverse={true} spell={s} onSelect={onPrepare} allowRemove={true} removeLabel={"Erase"} onRemove={onUnscribe}/>
                })}
            </Accordion> :
            <Row>
                <Col xs={6} className={"brand"}>You have lost your spell book! Buy Another?</Col>
                <Col xs={3} style={{textAlign: "right"}}>{(market?.["Miscellaneous Equipment"] || []).find(({type}) => type === "Book, Spell (Blank)")?.cost?.city}</Col>
                <Col xs={{span: 2, offset: 1}}><Button className={"btn-sm"} onClick={buyNewSpellbook}>Buy <i className="bi-plus"/></Button></Col>
            </Row>
        }
        {showCreateSpell && 
        <CreateSpell showCreateSpell={showCreateSpell} setShowCreateSpell={setShowCreateSpell} spellBook={spellBook}/>}
    </Container>
}

export default function CharacterSpells() {
    const {sources} = useContext(RulesetContext)
    const [spells, setSpells] = useState(null)
    const { saveItem, items } = useContext(InventoryContext)
    const [spellBook, setSpellBook] = useState()
    const {addNotice} = useContext(NoticeContext)
    const {character, updateCharacter} = useContext(CharacterContext)
    const [prepared, setPrepared] = useState(character?.skills?.spells)
    const currentLevel = character?.instance?.level || 1
    const spellType = character?.class?.name === "Cleric" ? "Cleric" : "Magic-User"

    useEffect(() => {
        if (character?.id && items && sources) {
            setSpells(sources.spells)
            setPrepared(character?.skills?.spells)
            setSpellBook(items.find((item) => item?.type === "Book, Spell (Blank)"))
        }
    }, [sources, character, items])

    useEffect(() => {
        if (character?.id && prepared) {
            updateCharacter(character, "skills", {spells: prepared})
        }
    }, [prepared])

    const getCurrentProgress = () => {
        return character?.class?.progression?.find(({level}) => level === currentLevel) || character?.class?.progression.slice(-1)[0]
    }
    const canPrepareMore = (inquiredLevel) => {
        const currentProgress = getCurrentProgress()
        return (prepared || []).filter(({level}) => level === parseInt(inquiredLevel, 10)).length < currentProgress.spells[inquiredLevel]
    }

    const onPrepare = (spell) => {
        if (!canPrepareMore(spell.level)) {
            const currentProgress = getCurrentProgress()
            addNotice("Error", `You can only prepare ${currentProgress.spells[spell.level]} level ${spell.level} spells.`, "danger")
            return
        }
        setPrepared([spell, ...(prepared || [])])
    }

    const onScribe = (spell) => {
        if (spellBook) {
            saveItem({...spellBook, spells: [...(spellBook?.spells || []), spell]})
        }
    }

    const currentProgress = getCurrentProgress()

    if (!character) {
        return null
    }

    if (!currentProgress?.spells) {
        return <Container>
            <h1 className="brand">{character?.settings?.name} does not possess spell casting abilities.</h1>
        </Container>
    }

    return <Container>
        <h1 className="brand">{spellType} Spells</h1>
        <ListGroup>
            <ListGroup.Item>
                <h3 className="brand">Prepared Spells</h3>
            </ListGroup.Item>
            {(prepared || []).map((s) => {
                return <ListGroup.Item key={s.id}>
                    <Row>
                        <Col>
                            <h6 className="brand">{s.name}</h6>
                        </Col>
                        <Col className="text-end">
                            <Button onClick={() => {
                                setPrepared(prepared.filter((p) => p.id !== s.id))
                            }}>Unprepare</Button>
                        </Col>
                    </Row>
                </ListGroup.Item>
            })}
        </ListGroup>
        <Accordion>
            {Object.entries(currentProgress?.spells || {}).map(([spellLevel, available], i) => {
                const canPrepare = canPrepareMore(spellLevel)
                return <Accordion.Item key={`spellLevel-${i}`} eventKey={spellLevel}>
                    <Accordion.Header><h3 className="brand">Level {spellLevel} {prepared?.filter(({level}) => {
                        return level === parseInt(spellLevel, 10)
                    })?.length}/{available}</h3></Accordion.Header>
                    <Accordion.Body>
                        {spellType === "Magic-User" && <SpellBook spellBook={spellBook} spellType={"Magic-User"} spellLevel={spellLevel} spells={spells} canPrepare={canPrepare} onPrepare={onPrepare} />}
                        <h4 className="brand">Spells</h4>
                        <Accordion>
                            {(spells || []).filter(({level, type}) => level == spellLevel && type === spellType).map((s) => {
                                const isArcane = spellType === "Magic-User"
                                return <SpellTemplate selectLabel={isArcane ? "Scribe" : "Prepare"} canSelect={isArcane ? true : canPrepare} key={s.name} allowReverse={!isArcane} spell={s} onSelect={isArcane ? onScribe : onPrepare} />
                            })}
                        </Accordion>
                    </Accordion.Body>
                </Accordion.Item>
            })}
        </Accordion>
    </Container>
}