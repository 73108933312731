import React, { useContext, useEffect } from "react"
import { Button } from "react-bootstrap"
import CharacterContext from "services/CharacterContext"
import DiceContext from "services/DiceContext"
import Beyond20Context from "services/Beyond20Context"
import { signedPrefix } from "services/util"

function translateAttackRoll(result, d) {
    const parts = d.split("+")
    return {
        "critical-failure": false,
        "critical-limit": 20,
        "critical-success": false,
        "discarded": false,
        "fail-limit": null,
        "formula": d,
        "parts": [{
            amount: result.qty,
            faces: parseInt(result.sides.replace("d", ""), 10),
            formula: parts[0],
            modifiers: "",
            rolls: [...result.rolls.map(({value}) => ({roll: value}))]
        }, "+", parseInt(parts[1] || 0, 10)],
        "total": result.value,
        "type": "to-hit"
    }
}

function translateDamageRoll(result, d) {
    const parts = d.split("+")
    const [amount, faces] = parts[0].split("d").map((v) => parseInt(v, 10))
    return ["Physical Damage", {
        formula: d,
        amount: amount,
        faces: faces,
        modifiers: parts[1],
        parts: [{
            roll: result.value
        }],
        total: result.value
    }, 1]
}

export function AttackDice({label, item, d, modifier=0, action, onResult, children}) {
    const {character, convertCharacter} = useContext(CharacterContext)
    const {addHandler, rollDice} = useContext(DiceContext)
    const {b20Settings} = useContext(Beyond20Context)

    const key = `${item.id}-${action}`
    useEffect(() => {
        addHandler(key, ([...result]) => {
            onResult(result)
            if (b20Settings) {
                const custom = new CustomEvent("Beyond20_rendered-roll", {detail: [{
                    action: "rendered-roll",
                    title: item.type,
                    character: character.settings.name,
                    attack_rolls: action === "attack" ? [translateAttackRoll(result, d, item)] : [],
                    damage_rolls: action === "damage" ? [translateDamageRoll(result, d)] : [],
                    play_sound: true,
                    open: false,
                    request: {
                        action : "roll",
                        type: "attack",
                        advantage: 0,
                        "attack-source": "item",
                        "attack-type": item.category === "Melee Weapon" ? "Melee" : "Range",
                        character: action === "attack" ? convertCharacter(character, b20Settings) : character.settings.name,
                        "damage-types": ["Physical"],
                        "damages": [item.damage],
                        name: item.type,
                        rollAttack: true
                    },
                    total_damages: []
                }]})
                document.dispatchEvent(custom)    
            }
        })
    }, [b20Settings])

    return <Button onClick={() => {
        rollDice(key, `${d}${modifier !== 0 ? signedPrefix(modifier) : ""}`)
    }}>{children || label || "Roll"}</Button>

}

export function SimpleDiceRoller(props) {
    const {label, d, onResult, children, ...rest} = props
    
    const {roll} = useContext(DiceContext)

    return <Button {...rest} onClick={() => {
        roll(d).then(onResult)
    }}>{children || label || "Roll"}</Button>

}
