import React, {useState} from "react"
import { Container } from "react-bootstrap"
import { capitalCase } from "services/util"

export default function Swappable({attributes, onSwap}) {
    const [firstSelection, setFirstSelection] = useState([])

    return <Container>
        <h1>Select items to swap.</h1>
        {Object.entries(attributes).map(([key, value]) => {
            return <div onClick={() => {
                if (firstSelection[0] && firstSelection[0] !== key) {
                    attributes[key] = firstSelection[1]
                    attributes[firstSelection[0]] = value
                    onSwap(attributes)
                    setFirstSelection([])
                } else {
                    setFirstSelection([key, value])
                }
            }} key={key} className={`btn ${(key === firstSelection[0]) ? "btn-success" : ""}`}>
                <span>{capitalCase(key)}</span> <span>{value?.value} ({value?.modifier})</span>
            </div>
        })}
    </Container>
}