import React, {useState} from "react"

export default function BlurredLoaderBackgroundImage(props) {
    const {Component, sm, src, size = "cover", repeat = "no-repeat", position = "center", height = "100vh", children, style, ...rest} = props
    const [currentSrc, setCurrentSrc] = useState(sm)
    const newImg = new Image()
    newImg.onload = () => {
        setCurrentSrc(src)
    }
    newImg.src = src
    return <Component style={{
        backgroundImage: `url(${currentSrc})`,
        backgroundSize: size,
        backgroundRepeat: repeat,
        backgroundPosition: position,
        height: height,
        // filter: `${currentSrc === sm ? "blur(10px)" : "none"}`,
        transition: "background-image 1s ease-in-out",
        ...style
    }} {...rest}>{children}</Component>
}
