import React, {createContext, useContext, useEffect, useMemo, useState} from "react"
import ClientContext from "./ajax"

const UserContext = createContext(null)

export function UserContextProvider ({children}) {
    const {checkToken, getCurrentUser} = useContext(ClientContext)
    const [user, setUser] = useState()
    const [loading, setLoading] = useState(true)
  
    useEffect(() => {
        checkToken().then((user) => {
            if (user.error) {
                setUser(null)
                setLoading(false)
                return
            } else {
                setUser(user)
                setLoading(false)    
            }
        }).catch(() => {
            setUser(null)
            setLoading(false)
        })
    }, [checkToken, getCurrentUser])

    useEffect(() => {
        if (user) {
            sessionStorage.setItem("user", JSON.stringify(user))
        }
    }, [user])

    const value = useMemo(() => {
        return {user, setUser, loading}
    }, [user, loading])

    return <UserContext.Provider value={value}>
        {children}
    </UserContext.Provider>
}

export default  UserContext
