import React, {createContext, useState, useContext, useEffect, useRef} from "react"
import NoticeContext from "./NoticeContext"
import UserContext from "./UserContext"
import DiceParser from "@3d-dice/dice-parser-interface"
import dr1 from "assets/diceroll1.mp3"
import dr2 from "assets/diceroll2.mp3"
import dr3 from "assets/diceroll3.mp3"
import dr4 from "assets/diceroll4.mp3"
import DiceBox from "@3d-dice/dice-box"

const DiceContext = createContext(null)

let el = document.getElementById("dice-box")
if (!el) {
    el = document.createElement("div")
    el.id = "dice-box"
    document.body.appendChild(el)
}

const config = {
    id: "dice-canvas",
    assetPath: "/assets/",
    gravity: 1,
    mass: 1,
    friction: 0.8,
    restitution: 0,
    angularDamping: 0.4,
    linearDamping: 0.4,
    spinForce: 5,
    throwForce: 6,
    startingHeight: 8,
    settleTimeout: 5000,
    offscreen: true,
    delay: 30,
    lightIntensity: 1,
    enableShadows: true,
    shadowTransparency: 0.8,
    theme: "wooden",
    themeColor: "#2e8555",
    scale: 6,
    suspendSimulation: false,
    // onDieComplete: (results) => {},
    // onRollComplete: (results) => {},
    // onThemeConfigLoaded: (config) => {},
    // onThemeLoaded: (theme) => {},
}


export function DiceProvider({children}) {
    const {user} = useContext(UserContext)
    const [handlers, setHandlers] = useState({})
    const {addNotice} = useContext(NoticeContext)
    const [sound, setSound] = useState(false)
    const [roll3d, setRoll3d] = useState(false)
    const DP = useRef(null)
    const [initialized, setInitialized] = useState(false)
    const [Dice, setDice] = useState(null)

    const requestDice = () => {
        if (Dice) return
        setDice(new DiceBox("#dice-box", config))
    }

    useEffect(() => {
        if (user) {
            setSound(user?.settings?.sound === false ? false : true)
            setRoll3d(user?.settings?.roll3d === false ? false : true)
        }
    }, [user])

    useEffect(() => {
        if (Dice && Dice.init) {
            Dice.init().then(() => {
                setInitialized(true)
                document.addEventListener("mousedown", () => {
                    const diceBoxCanvas = document.getElementById("dice-canvas")
                    if (window.getComputedStyle(diceBoxCanvas).display !== "none") {
                        Dice.hide().clear()
                    }
                })
            })
        
            DP.current = new DiceParser()    
        }

        return () => {
            if (Dice?.remove) {
                Dice.remove()
            }
        }
    }, [Dice])
    
    useEffect(() => {
        if (initialized && Dice.updateConfig) {
            Dice.updateConfig({
                ...config,
                suspendSimulation: !roll3d
            })    
        }
    }, [roll3d, initialized])

    const addHandler = (key, cb) => {
        if (handlers[key]) {
            if (handlers[key] === cb) {
                console.log(`handler already set for dice ${key}`)
            } else {
                handlers[key] = cb
                setHandlers({...handlers})
            }
        } else {
            handlers[key] = cb
            setHandlers({...handlers})
        }
    }

    const diceSounds = [dr1, dr2, dr3, dr4]

    function diceRollSound() {
        const snd1  = new Audio()
        const src1  = document.createElement("source")
        src1.type = "audio/mpeg"
        diceSounds.push(diceSounds.shift())
        src1.src  = diceSounds[0]
        snd1.appendChild(src1)
        document.body.appendChild(snd1)
        snd1.play()
        setTimeout(() => {
            snd1.remove()
            src1.remove()
        }, 1000)
    }


    const roll = (d) => {
        try {
            const formula = DP.current.parseNotation(d)
            if (sound) {
                formula.forEach(({qty}) => {
                    for (let i = 0; i < qty; i++) {
                        setTimeout(() => {
                            diceRollSound()
                        }, i * 50)
                    }
                })
            }
            
            return Dice.show().roll(formula)
        } catch(e) {
            addNotice("Error", "There was an error with the dice roll. Please try again.", "danger")
        }
    }

    const rollDice = (key, d) => {
        try {
            const formula = DP.current.parseNotation(d)
            if (sound) {
                formula.forEach(({qty}) => {
                    for (let i = 0; i < qty; i++) {
                        setTimeout(() => {
                            diceRollSound()
                        }, i * 50)
                    }
                })
    
            }
            Dice.show().roll(d).then((...result) => {
                console.log(result)
                handlers[key](...result)
            })
        } catch(e) {
            addNotice("Error", "There was an error with the dice roll. Please try again.", "danger")
        }
    }

    return <DiceContext.Provider value={{
        addHandler, rollDice, roll, sound, setSound, roll3d, setRoll3d, requestDice
    }}>
        {children}
    </DiceContext.Provider>

}

export default DiceContext