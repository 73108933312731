import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import React, { useState, useEffect, useContext } from "react"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import defaultAvatar from "assets/avatar.jpg"
import UserContext from "services/UserContext"
import API_HOST from "services/api"
import ImageManager from "components/ImageManager"
import ClientContext from "services/ajax"
import { SubscriptionProduct } from "./Subscribe"
import { capitalCase } from "services/util"
import { CharacterAvatar, CampaignAvatar } from "components/Avatar"

export default function Profile() {
    const {
        user,
        setUser
    } = useContext(UserContext)
    const { 
        uploadImage, 
        getCharacters,
        getCampaigns,
        getRelatedImages, 
        updateUser, 
        deleteImage, 
        getUserSubscription, 
        getUserPaymentMethods 
    } = useContext(ClientContext)
    const [campaigns, setCampaigns] = useState([])
    const [characters, setCharacters] = useState([])
    const [images, setImages] = useState([{preview: defaultAvatar}])
    const [name, setName] = useState(user?.name)
    const [avatar, setAvatar] = useState(user?.avatar)
    const [subsciptions, setSubscriptions] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])

    useEffect(() => {
        getUserSubscription().then((subscriptions) => {
            setSubscriptions(subscriptions)
        })
        getUserPaymentMethods().then((paymentMethods) => {
            setPaymentMethods(paymentMethods)
        })
        getCampaigns().then((campaigns) => {
            setCampaigns(campaigns)
        })
        getCharacters().then((characters) => {
            setCharacters(characters)
        })
    }, [])

    const onChange = (imageList) => {
        setImages(imageList)
    }

    const onImageSelect = async (index) => {
        const image = images[index]
        if (image.file) {
            // we need to upload it first
            const i = await uploadImage(image.file, user.id)
            setAvatar(i.location)
        } else {
            setAvatar(images[index].preview)
        }
        images.forEach(i => delete i.selected)
        images[index].selected = true
        setImages(images)
    }

    const removeImage = (index) => {
        const image = images[index]
        const id = image.preview.split("/").slice(-1)[0].split(".")[0]
        deleteImage(id).then(() => {
            setAvatar(avatar)
        })
    }

    useEffect(() => {
        getRelatedImages(user.id).then((images) => {
            const i = images.map(i => {
                const url = i.location || `${API_HOST}/api/image/_d/${i.owner_id}/${i.id}.${i.original_name.split(".").slice(-1)[0]}`
                return {
                    preview: url,
                    selected: url === avatar
                }
            })
            i.unshift({preview: defaultAvatar, selected: defaultAvatar === avatar})
            setImages(i)
        })
    }, [user?.id, avatar])

    const selectionSaved = user.avatar !== avatar ? "warning" : "success"
    const onSave = async () => {
        const uploads = images.filter(i => i.file).map(({file}) => uploadImage(file, user.id))
        Promise.all(uploads).then(() => {
            updateUser({name, avatar}).then((_user) => {
                const newUser = {...user, ..._user}
                sessionStorage.setItem("user", JSON.stringify(newUser))
                setUser(newUser)
            })
        })
    }

    return (<Container className="mt-3">
        <Form className="vstack gap-3">
            <Row>
                <Col>
                    <Form.Group>
                        <Form.FloatingLabel label="Display Name">
                            <Form.Control type="text" placeholder="display name" defaultValue={name} onChange={({target}) => setName(target.value)}></Form.Control>
                        </Form.FloatingLabel>
                    </Form.Group>
                    <Form.Group className="mt-2">
                        <ImageManager placeholder="avatar" onChange={onChange} onImageSelect={onImageSelect} images={images} removeImage={removeImage} selectionSaved={selectionSaved}/>
                    </Form.Group>
                    <Button className="mt-2" onClick={onSave}>Save</Button>
                    <Container className="mt-3">
                        <h1>Characters</h1>
                        {characters.map((c) => {
                            return <CharacterAvatar key={c.id} title={c?.settings?.name} character={c}/>
                        })}
                        <h1>Campaigns</h1>
                        {campaigns.map((c) => {
                            return <CampaignAvatar key={c.id} campaign={c}/>
                        })}
                    </Container>
                </Col>
                <Col>
                    <h1>Subscriptions</h1>
                    {subsciptions.map((s) => {
                        const product = s.stripe.product
                        product.prices = [s.stripe.price]
                        return <SubscriptionProduct key={s.id} sub={s.stripe.sub} product={s.stripe.product}/>
                    })}
                    <h1>Payment Methods</h1>
                    {paymentMethods.map((p) => {
                        return <Card key={p.id}>
                            <Card.Header>
                                <h3>{capitalCase(p.card.brand)}</h3>
                            </Card.Header>
                            <Card.Body>
                                <div>{p.billing_details.name}</div>
                                <div>{p.card.last4} {p.card.exp_month}/{p.card.exp_year - 2000} </div>
                            </Card.Body>
                        </Card>
                    })}
                </Col>
            </Row>
        </Form>
    </Container>)
}