import React from "react"
import ReactImageUploading from "react-images-uploading"
import CloseButton from "react-bootstrap/CloseButton"

export default function ImageManager({images, onChange, onImageSelect, removeImage, selectionSaved, rounded = "circle"}) {
    const borderColor = selectionSaved ? "success" : "warning"
    return (<ReactImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={10}
        dataURLKey="preview"
    >
        {({
            imageList,
            onImageUpload,
            isDragging,
            dragProps
        }) => {
            return (
                <div className="upload__image-wrapper">
                    <div className="hstack">
                        <button {...dragProps} className={`rounded-${rounded} position-relative bg-white m-1 border border-${isDragging ? "danger" : "secondary"} border-2 d-flex align-items-center justify-content-center`} style={{
                            width: "5em", 
                            height: "5em",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onImageUpload()
                        }}
                        ><i className="bi-plus fs-1"/></button>
                        {imageList.map((image, index) => (
                            <div 
                                key={index} 
                                className={`rounded-${rounded} position-relative bg-white m-1 ${image.selected ? `border border-${borderColor} border-2` : "border-0"}`}
                                style={{
                                    width: "5em", 
                                    height: "5em",
                                    backgroundImage: `url(${image["preview"]})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center"
                                }}
                                onClick={() => onImageSelect(index)}  
                            >
                                <CloseButton as={"a"} className={"position-absolute top-0 end-0"} onClick={(e) => {
                                    e.stopPropagation()
                                    removeImage(index)
                                }} />
                            </div>
                        ))}
                    </div>
                </div>
            )}}
    </ReactImageUploading>)
}
