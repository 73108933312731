import React, {createContext, useState, useMemo} from "react"
import { Toast, ToastContainer } from "react-bootstrap"

const NoticeContext = createContext(null)

export function NoticeContextProvider({children}) {
    const [notices, setNotices] = useState([])

    const addNotice = (title = "", message, type = "primary") => {
        if (title.type && title.message) {
            setNotices([...notices, title])
        } else {
            setNotices([...notices, {message, type, title}])
        }
    }
    const titles = {
        success: "Success",
        warning: "Warning",
        danger: "Error"
    }

    const value = useMemo(() => ({notices, addNotice}), [notices])
    return <NoticeContext.Provider value={value}>
        {children}
        <ToastContainer className="fixed-bottom mb-5" containerPosition="" position={"bottom-center"} style={{zIndex: "9999"}}>
            {notices.map((notice, index) => <Toast key={index} onClose={() => {
                notices.splice(index, 1)
                setNotices([...notices])
            }} show={true} delay={5000} autohide animation={false} className={`bg-${notice.type}`}>
                <Toast.Header><strong>{notice.title || titles[notice.type]}</strong></Toast.Header>
                <Toast.Body>{notice.message}</Toast.Body>
            </Toast>)}
        </ToastContainer>
    </NoticeContext.Provider>
}

export default  NoticeContext