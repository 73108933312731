import React, {useContext} from "react"
import { useParams } from "react-router-dom"
import CharacterContext from "services/CharacterContext"
import { Container } from "react-bootstrap"
import {Link} from "react-router-dom"
export default function StepWizard({ system, build, children, stepNavigator }) {
    const { step } = useParams()
    const { character } = useContext(CharacterContext)

    const currentStep = parseInt(step, 10)
    const max = children?.length
    
    if (!character?.id) return null
    let prevStep = currentStep - 1
    while(prevStep >= 0 && !children[prevStep]) {
        prevStep--
    }
    let prevUrl = `/characters/builder/${system}/${build}/${character.id}/${Math.max(prevStep, 0)}`
    if (prevStep < 0) {
        prevUrl = "/characters"
    }
    let nextStep = currentStep + 1
    while(nextStep <= max && !children[nextStep]) {
        nextStep++
    }
    let nextUrl = `/characters/builder/${system}/${build}/${character.id}/${Math.min(nextStep, max - 1)}`
    if (nextStep >= max) {
        nextUrl = (`/character/${character.id}`)
    }

    return <Container fluid className={"gx-0"}>
        <Container fluid className={"d-flex bg-white vw-100 sticky-top justify-content-between"} style={{
            marginLeft: "-1.5rem",
            marginRight: "-1.5rem",
        }}>
            <Link disabled={!character?.id} to={prevUrl} className="btn btn-lg text-primary">
                <i className={"bi-arrow-left-circle-fill"}/>
            </Link>
            <Container style={{
                overflowX: "auto"
            }}>
                {stepNavigator}
            </Container>
            <Link className={"btn btn-lg text-primary"} disabled={!character?.id} to={nextUrl}>
                <i className={"bi-arrow-right-circle-fill"}/>
            </Link>
        </Container>
        <Container className={"gx-0"}>
            <div className='current-step'>
                {children[currentStep]}
            </div>
        </Container>
        <Container className={"d-flex bg-white justify-content-between mx-auto"}>
            <Link disabled={!character?.id} to={prevUrl} className="btn btn-lg text-primary">
                <i className={"bi-arrow-left-circle-fill"}/> Back
            </Link>
            <Link className={"btn btn-lg text-primary"} disabled={!character?.id} to={nextUrl}>
                <i className={"bi-arrow-right-circle-fill"}/> Next
            </Link>
        </Container>
    </Container>
}
